<template>
	<div class="module_3">
		<h2>{{vrvInfo.template_name}}</h2>
		<div class="title3">
			<div class="label3 ">
			<div class="title-img3 ">
			<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/power-off.png" alt="">
			</div>
			<span><big>{{vrvInfo.run_status}} | {{vrvInfo.run_days}}</big><small>天</small></span>
			</div>
			<div class="label3">
			<div class="title-img3  ">
			<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/snow.png" alt="">
			</div>
			<span><big>{{vrvInfo.temperature_mode}}模式</big></span>
			</div>
			<!--  -->
			<div class="label3">
			<div class="title-img3">
			<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/weater.png" style="width: 2rem; height: 2rem;margin-top: 3px;" alt="">
			</div>
			<!-- <span>{{vrvInfo.wendu}} ℃ | {{vrvInfo.wendu_percent}} %</span> -->
			<span><big>室外温湿度 {{vrvInfo.wendu}}</big><small>℃</small> | <big>{{vrvInfo.wendu_percent}}</big><small>%</small></span>
			</div>
			</div>
		<div class="item3">
			<div class="item-box3">
				<span><big>{{vrvInfo.machine_num}}</big><small> 台</small> / <big>{{vrvInfo.machine_percent}}</big><small> %</small></span>
				<div class="bottom-line3"></div>
				<div class="item-image3 img-1">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/003_2.png" alt="">
				</div>
				<span class="span-1">{{vrvInfo.building_info.template_vrv.system_name||"VRA多联机"}}</span>
			</div>
			<div class="item-box3">
					<span><big>{{vrvInfo.average_temperature}}</big><small> ℃</small></span>
				<div class="bottom-line3"></div>
				<div class="item-image3">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/temperature.png" alt="">
				</div>
				<span class="span-1">房间平均温度</span>
			</div>
			<!-- <div  class="item-box3">
				<span><big>{{vrvInfo.average_rund_hours||0}}</big><small> 小时</small></span>
				<div class="bottom-line3"></div>
				<div class="item-image3">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/moxingyunhang4.png" alt="">
				</div>
				<span class="span-1">平均运行时间</span>
			</div> -->
			<div class="item-box3">
				<span><big>{{vrvInfo.online_machine_num}}</big><small> 台</small> / <big>{{vrvInfo.online_machine_percent}}</big><small> %</small></span>
				<div class="bottom-line3"></div>
				<div class="item-image3">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/link.png" alt="">
				</div>
				<span class="span-1">设备在线数量</span>
			</div>
		</div>
			<div class="item3 item—3">
					<div class="item-box3">
						<span><big>{{vrvInfo.day}}</big><small> KWh</small> <!-- /<big>66</big><small> %</small> --> </span>
						<div class="bottom-line3"></div>
						
						<div class="item-image3">
						<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/dian.png" alt="">
						</div>
						<span class="span-1">今日用电</span>
					</div>
					<div class="item-box3">
						<span><big>{{vrvInfo.month}}</big><small> KWh</small><!-- /<big>66</big><small> %</small> --> </span>
						<div class="bottom-line3"></div>
						<div class="item-image3">
						<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/009.png" alt="">
						</div>
						<span class="span-1">本月用电</span>
					</div>
					<div  class="item-box3">
						<span><big>{{vrvInfo.year}}</big><small> KWh</small> <!-- /<big>66</big><small> %</small> --> </span>
						<div class="bottom-line3"></div>
						<div class="item-image3">
						<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/010.png" alt="">
						</div>
						<span class="span-1">今年用电</span>
					</div>
				</div>
		
		<div  class="echarts-line3">
			<div id="line3" style="width: 93.75rem; height: 22rem; margin: 2rem auto 0;">
				
			</div>
		</div>
	</div>
</template>

<script>
		import {EventBus} from "/src/util/event-bus.js";
	export default{
		data() {
			return{
				id: '',// 接收
				vrvInfo:{
					building_info:{
						template_vrv:[]
					}
				},
				buildingId:""
			}
		},
		created() {
			EventBus.$on("id", (id) => {
			  // index发送来的消息
			  this.buildingId = id;
			// this.get_vrvInfo( this.buildingId)
			this.echartsLine3(this.buildingId)
			});
				},
		methods:{
			// get_vrvInfo(buildingId){
			// 	this.$axios.get('/api/web/building/vrv?building_id='+buildingId).then(res=>{
			// 		// debugger
			// 		if (res.errcode == 0) {
			// 		this.vrvInfo=res.data
			// 		// console.log(this.vrvInfo,"我有数据3333333333")
			// 		}else{
			// 			console.log("我没有数据333333333333")
			// 			}
			// 	})
			// },
			echartsLine3(buildingId){
				this.$axios.get('/api/web/building/vrv?building_id='+buildingId).then(res=>{
				if (res.errcode == 0) {
					this.vrvInfo=res.data
					console.log(this.vrvInfo,"我有数据3333333333")
					let xArr1=[]
					let xArr2=[]
					let xArr3=[]
					const yArr=['00','01', '02','03', '04', '05' , '06', '07','08','09','10', '11', '12','13','14','15','16','17','18','19','20','21','22','23'];
					let charts=res.data.chart
					let chartKV={}//定义一个空的chartKV
					for(var item of charts){
						//循环charts，将item赋值给chartKV，并拿到item.hour，也就是我们x轴的01-24
						chartKV[item.hour]=item
					}
					for(var k of yArr){
						//再次循环我们上面定义的常量yArr，我们要理解一下 循环 in of 的区别 in 循环的是索引，of循环的是item
						if(chartKV[k]){      //如果hartKV[k]存在，我们就在变量后面push添加我们要的数据
							// console.log(chartKV[k])
							xArr1.push(chartKV[k].average_temperature)
							xArr2.push(chartKV[k].machine_num)
							xArr3.push(chartKV[k].out_temperature)
						}else{   //否者就是0
							xArr1.push("")
							xArr2.push("")
							xArr3.push("")
						}
					}
					
					// for (let item of res.data.chart) {
					// 	// debugger
					// 	xArr1.push(item.average_temperature)
					// 	xArr2.push(item.machine_num)
					// 	xArr3.push(item.out_temperature)
					// 	// yArr.push(item.hour)
					// }
					let myChart = this.$echarts.init(document.getElementById("line3"))
					var options = {
						 tooltip: {
						        trigger: 'axis',
						        axisPointer: {
						            type: 'cross',
						            crossStyle: {
						                color: '#999'
						            }
						        }
						    },
							title: [{
									top:'15',
							       left: 'center',
							       text: 'VRV系统运行',
								   textStyle: {
									   color: "rgba(245, 242, 242, 1)",
										fontWeight: "500"
									  },
							   }],
						    legend: {
						        data: ['设备运行台数', '室外温度', '房间平均温度'],
								x:'center',
								y:'bottom',
								textStyle: {
												color: '#ffffff',
												fontSize: '16',
											}
								},
						
						    xAxis: [
						        {
						            type: 'category',
						            data:yArr,
						            axisPointer: {
						                type: 'shadow'
						            },
									axisLabel: {
										textStyle: {
											color: '#c8c8c8',
											fontSize: '12' //字体大小设置
										}
									},
									
						        }
						    ],
						    yAxis: [
						        {
						            type: 'value',
						            // name: '数量',
						            // min:0,
						            // max: 400,
						            // interval: 50,
									axisLabel: {
										  formatter: '{value}',
										textStyle: {
											color: '#c8c8c8',
											fontSize: '12' //字体大小设置
										}
									},
									splitLine: { //网络格的显示，和颜色设置
												show: true,
												lineStyle: {color: ['#0b4aaf'],}
											}
						        },
						        {
						            type: 'value',
									splitLine:false,  //横线消失
						            // name: '温度',
						            // min: 0,
						            // max:50,
						            // interval: 10,
						     //        axisLabel: {
										 // show: true,
						               
						     //        },
									axisLabel: {
										 formatter: '{value} °C',
											textStyle: {
												color: '#c8c8c8',
												fontSize: '12' //字体大小设置
											}
										},
						        }
						    ],
						    series: [
						        {
						            name: '设备运行台数',
						            type: 'bar',
						            data: xArr2,
									itemStyle: {
										normal: {
											color: '#71d655'
										}
									}
						        },
								{
								    name: '房间平均温度',
								    type: 'line',
								    yAxisIndex: 1,
								    data:xArr1
								},
						        {
						            name: '室外温度',
						            type: 'line',
						            data: xArr3,
									yAxisIndex: 1,
									itemStyle: {
										normal: {
											color: '#ba7bb5'
										}
									}
						        }
						       
						    ]
					};
			myChart.setOption(options)
			}
			})
				},
				
		}
	}
</script>

<style>
	*{
		padding: 0;
		margin: 0;
	}
	.module_3{
		margin: 0 auto;
		width: 100%;
		height: 53.75rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mbbg.png) no-repeat;
		background-size: 100% 100%;
		color: #FFFFFF;
	}
	.title3{
		margin:3.125rem auto 1rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg1.png)no-repeat;
		background-size: 100% 100%;
		width: 85%;
		height: 4rem;
		line-height: 4.7rem;
		text-align: center;
		font-size:1.2rem ;
		display: flex;
		justify-content: center;
	}
	.label3{
		width: 33%;
		position: relative;
	}
	.title-img3{
		position: absolute;
		width: 2.5rem;
		height: 2.5rem;
		line-height:3rem ;
		border-radius: 50%;
		border:0.0625rem #fff solid;
		 background: #09337B;
		 top: -1.45rem;
		 left: 15.5rem;
	}

	
	.title-img3 img{
		width: 1.5rem;
		height: 1.5125rem;
	}
	.item3{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.item—3{
		margin: 0.625rem 6.25rem;
		justify-content: space-around;
	}
	.item-box3{
		position: relative;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
		background-size: 100% 100%;
		text-align: center;
		    width: 25rem;
		    height: 8rem;
		padding-top: 1.5rem;
	}
	.item-image3{
		position: absolute;
		    line-height: 2.525rem;
		    border-radius: 50%;
		    left: 9rem;
		    top: 4.4rem;
	}
	.item-image3 img{
		width:1.2rem;
		height:1.2rem;
	}
	.bottom-line3{
		margin: .7rem auto;
		width:50%;
		border-bottom: 1px #fff solid;
		opacity: .5;
	}

	.echarts-line3{
		margin: 1.625rem auto;
		width: 100%;
		height: 23rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg1.png)no-repeat;
		background-size: 100% 100%;
	}
</style>
