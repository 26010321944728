<template>
	<div class="module_1">
		<h2>{{buildingInfo.template_name}}</h2>
		<!-- <h2>用电</h2> -->
		<!-- 	<div class="title">共计监测点位 N 个</div> -->
		<div class="top2" style="margin: -1.25rem 0 0 0;">
			<div class="label2" >
				<div class="title-img2" style="border-radius: none;">
					<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/tpf.png" alt="">
				</div>
				<span><big>碳排放:{{buildingInfo.absorptionCarbon}}</big><small>吨</small></span>

			</div>
			<!--  -->
			<div class="label2 ">
				<div class="title-img2 ">
					<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/txs.png" alt="">
				</div>
				<span><big>碳吸收:{{buildingInfo.consumedCarbon||0}}</big><small>吨</small></span>
			</div>
		</div>

		<div class="item1">
			<div class="item-box1">
				<span><big>{{buildingInfo.day}}</big><small> kWh</small></span>
				<div class="bottom-line1"></div>
				<div class="item-image1">
					<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/dian.png" alt="">
				</div>
				<span class="span-1">今日用电</span>
			</div>
			<div class="item-box1">
				<span><big>{{buildingInfo.month}}</big><small> kWh</small></span>
				<div class="bottom-line1"></div>
				<div class="item-image1">
					<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/009.png" alt="">
				</div>
				<span class="span-1">本月用电</span>
			</div>
			<div class="item-box1">
				<span><big>{{buildingInfo.year}}</big><small> kWh</small></span>
				<div class="bottom-line1"></div>
				<div class="item-image1">
					<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/010.png" alt="">
				</div>
				<span class="span-1">今年用电</span>
			</div>
			<div class="item-box1">
				<span><big>{{buildingInfo. areaPerYear}}</big><small> kWh/(m2.y)</small></span>
				<div class="bottom-line1"></div>
				<div class="item-image1">
					<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/005.png" alt="" style="margin-left: -20px;">
				</div>
				<span class="span-1">年单位面积用电</span>
			</div>
			<div class="item-box1">
				<span><big>{{buildingInfo.peoplePerYear}}</big><small> kWh/(r.y)</small></span>
				<div class="bottom-line1"></div>
				<div class="item-image1">
					<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/006.png" alt="">
				</div>
				<span class="span-1">年人均用电</span>
			</div>
		</div>


		<div class="echarts-line1">
			<div class="time1">
				<div class="time-item1">
					<span v-for="(item,index) in periodList" :key="index" @click="changePeriod(index)"
						:class="index==selectedPeriodIndex?'active':''">{{item.label}}</span>
				</div>
				<el-select v-model="chartsParam.type" placeholder="" @change="renderCharts">
					<el-option v-for="item in chartsTypeList" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div id="line" style="width: 93.75rem; height: 23rem; margin: 0 auto;">
			</div>
			<!-- <div style="text-align: center; font-size: 1.3rem;">苏州湾大厦2020总建筑用水量</div> -->
		</div>
	</div>
</template>

<script>
	import {
		EventBus
	} from "/src/util/event-bus.js";
	// EventBus.$off()
	export default {
		data() {
			return {
				chartsTypeList: [{
					value: "building",
					label: "建筑总量"
				}, {
					value: "people",
					label: "人均用电"
				}, {
					value: "area",
					label: "单位面积"
				}],
				chartsParam: {
					type: "building",
					period: null,
					only_charts: true,
					template_type: "electric"
				},


				periodList: [{
					value: "hour",
					label: "时"
				}, {
					value: "day",
					label: "天"
				}, {
					value: "month",
					label: "月"
				}, {
					value: "year",
					label: "年"
				}],
				selectedPeriodIndex: 2,
				buildingInfo: "",
				calculate: null,
				// 
				id: ''
			}
		},
		created() {
			EventBus.$on("id", (id) => {
				// index发送来的消息
				this.buildingId = id;
				console.log(this.buildingId,"000999")
				// debugger
				this.$axios.get('/api/web/electric?building_id=' + this.buildingId  + '&template_type=electric')
					.then(res => {
						if (res.errcode == 0) {
							this.buildingInfo = res.data
							// console.log(this.buildingInfo,"我有数据")
						} else {
							console.log("我没有数据")
						}
					})
				this.changePeriod(1)
				this.renderCharts()
			});
		},
		methods: {
			changePeriod(index) {
				if (this.selectedPeriodIndex != index) {
					this.selectedPeriodIndex = index
					this.renderCharts()
				}
			},
			renderCharts() {
				// debugger
				this.chartsParam.period = this.periodList[this.selectedPeriodIndex].value
				// console.log(this.chartsParam.period,"nnnnnnnnnnnnn")
				this.$axios.post('/api/web/electric?building_id=' + this.buildingId, this.chartsParam).then(res => {
					if (res.errcode == 0) {
						this.renderLineCharts(res.data.charts);
					}
				})
			},
			renderLineCharts(data) {
				let xArr = Object.keys(data)
				let yArr = Object.values(data)
				let EchartsDom = this.$echarts.init(document.getElementById("line"))
				let option = {
					xAxis: {
						type: 'category',
						data: xArr,
						axisLabel: {
							interval: 0, // 月份全部显示
							textStyle: {
								color: '#c8c8c8',
								fontSize: '12' //字体大小设置
							}
						},
					},
					tooltip: {
						trigger: 'axis'
					},
					yAxis: {
						type: 'value',
						splitLine: { //网络格的显示，和颜色设置
							show: true,
							lineStyle: {
								color: ['#0b4aaf'],
							}
						},
						axisLabel: {
							textStyle: {
								color: '#c8c8c8',
								fontSize: '12' //字体大小设置
							}
						},
					},
					series: [{
						barWidth: '20',
						data: yArr,
						type: 'bar',
						smooth: true,
						itemStyle: {
							normal: {
								color: '#1ec895'
							}
						},
					}]
				}
				EchartsDom.setOption(option)
			},



		}
	}
</script>

<style lang="scss">
	* {
		padding: 0;
		margin: 0;
	}

	big {
		font-size: 1.3rem;
		font-weight: 500;
	}

	.module_1 {
		margin: 0 auto 0;
		width: 100%;
		// height: 53rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mbbg.png) no-repeat;
		background-size: 100% 100%;
		color: #FFFFFF;

		.item1 {
			margin-top: 1.875rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.item-box1 {
			position: relative;
			background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
			background-size: 100% 100%;
			/* margin: 0 1.25rem; */
			text-align: center;
			width: 25rem;
			height: 9.0625rem;
			padding-top: 1.8rem;
		}

		.item-image1 {
			position: absolute;
			/* width: 2.25rem;
		    height: 2.25rem; */
			line-height: 2.525rem;
			border-radius: 50%;
			/* border: 0.0625rem #fff solid; */
			left: 8.3125rem;
			top: 5.7125rem;
		}

		.item-image1 img {
			width: 1.2rem;
			height: 1.2rem;
		}

		.bottom-line1 {
			margin: 1.27rem auto;
			width: 50%;
			border-bottom: 0.0625rem #fff solid;
			opacity: .5;

		}

		.time1 {
			padding-top: 20px;
			margin: 10px auto 0;
			display: flex;
			justify-content: space-between;
			width: 80%;
		}

		.time-item1 span {
			background-color: #071D3C;
			margin: 0 0.1625rem;
			display: inline-block;
			width: 2.625rem;
			height: 2.625rem;
			color: #696969;
			line-height: 2.625rem;
			text-align: center;

		}

		.el-select {
			/* margin: 0.625rem; */
			width: 7.3125rem;
			height: 2.625rem;
		}

		.el-select input {
			background: #0C9FE8;
			outline: none;
			border: none;
			color: #fff;
			text-align: center;
		}

		.time-item1 .active {
			background-color: #0C9FE8;
			color: #fff;
		}

		.echarts-line1 {
			margin: 1rem auto 0;
			width: 100%;
			height: 30rem;
			background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg1.png) no-repeat;
			background-size: 100% 100%;
		}
	}
</style>
