<template>
	<div class="module_6">
		<h2>{{environmentInfo.template_name}}</h2>
		<div class="title6">监测点位<big style="font-size: 2.5rem;">{{environmentInfo.watch_num}}</big> 个</div>
		<div class="item6">
			<div class="item-box6">
				<span><big>{{environmentInfo.TA}}</big><small> ℃</small></span>
				<div class="bottom-line6"></div>
				<div class="item-image6">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/temperature.png" alt="">
				</div>
				<span class="span-1">平均温度</span>
			</div>
			<div class="item-box6">
				<span><big>{{environmentInfo.TH}}</big><small> %</small></span>
				<div class="bottom-line6"></div>
				<div class="item-image6">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/humity.png" alt="">
				</div>
				<span class="span-1">平均湿度</span>
			</div>
			<div  class="item-box6">
				<span><big>{{environmentInfo.CC2}}</big><small> ppm</small></span>
				<div class="bottom-line6"></div>
				<div class="item-image6">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/CO2.png" alt="">
				</div>
				<span class="span-1">CO2浓度</span>
			</div>
			<div class="item-box6">
				<span><big>{{environmentInfo.PML}}</big><small> ug/m³</small></span>
				<div class="bottom-line6"></div>
				<div class="item-image6">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/PM25.png" alt="">
				</div>
				<span class="span-1">PM2.5</span>
			</div>
		</div>
		<div  class="echarts-line6">
			
			<el-carousel trigger="click" height="150px" direction="vertical" :autoplay="false">
			  <el-carousel-item>
			  <div id="environmen1" style="width: 93.75rem; height: 26rem; margin:  0 auto;"></div>
			  </el-carousel-item>
			 <el-carousel-item>
			 <div id="environmen2" style="width: 93.75rem; height: 26rem; margin:  0 auto;"></div>
			 </el-carousel-item>
			 <el-carousel-item>
			 <div id="environmen3" style="width: 93.75rem; height: 26rem; margin:  0 auto;"></div>
			 </el-carousel-item>
			 <el-carousel-item>
			 <div id="environmen4" style="width: 93.75rem; height: 26rem; margin:  0 auto;"></div>
			 </el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>

<script>
	import {EventBus} from "/src/util/event-bus.js";
	export default{
		data() {
			return{
							id: '',// 接收
							environmentInfo:{
								building_info:[]
							},
							buildingId:"",
						}
		},
		
		created() {
			EventBus.$on("id", (id) => {
			  // index发送来的消息
			  this.buildingId = id;
			  // console.log( this.buildingId,"33333333333333")
			this.get_environmentInfo( this.buildingId)
			// this.echartsLine6(this.buildingId)
			});
				},
		methods:{
			// 图标
			get_environmentInfo(buildingId){
					this.$axios.get('/api/web/building/environment?building_id='+buildingId).then(res=>{
						if (res.errcode == 0) {
							// debugger
							this.environmentInfo=res.data
							console.log(this.environmentInfo,"this.environmentInfo")
							this.get_environment(res.data.chart_data,'environmen1')
							this.get_environment(res.data.chart_data,'environmen2')
							this.get_environment(res.data.chart_data,'environmen3')
							this.get_environment(res.data.chart_data,'environmen4')
							// console.log(res.data.chart_data,"88888888888888888888")
							}
							})
			},
			get_environment(chartData,environmen){
					let yArr1=[]
					let yArr2=[]
					let yArr3=[]
					let yArr4=[]
					const xArr=['00','01', '02','03', '04', '05' , '06', '07','08','09','10', '11', '12','13','14','15','16','17','18','19','20','21','22','23'];
					let charts=res.data.chartData
					let chartKV={}//定义一个空的chartKV
					for(var item of charts){
						//循环charts，将item赋值给chartKV，并拿到item.day，也就是我们x轴的01-24
						chartKV[item.hour]=item
					}
					
					for(var k of xArr){
						//再次循环我们上面定义的常量yArr，我们要理解一下 循环 in of 的区别 in 循环的是索引，of循环的是item
						if(chartKV[k]){      //如果hartKV[k]存在，我们就在变量后面push添加我们要的数据
							// console.log(chartKV[k])
							yArr1.push(chartKV[k].CC2)
							yArr2.push(chartKV[k].PML)
							yArr3.push(chartKV[k].TH)
							yArr4.push(chartKV[k].TA)
						}else{   //否者就是0
							yArr1.push("")
							yArr2.push("")
							yArr3.push("")
							yArr4.push("")
						}
					}
					// for (let item of chartData) {
					// 	yArr1.push(item.CC2)
					// 	yArr2.push(item.PML)
					// 	yArr3.push(item.TH)
					// 	yArr4.push(item.TA)
					// }
					let myChart = this.$echarts.init(document.getElementById('environmen1'))
					let myChart2 = this.$echarts.init(document.getElementById('environmen2'))
					let myChart3 = this.$echarts.init(document.getElementById('environmen3'))
					let myChart4 = this.$echarts.init(document.getElementById('environmen4'))
					var options = {
						title: [{
							  top:'10',
						       left: 'center',
						       text: '建筑内环境记录-CO2',
							   textStyle: {
								   color: "rgba(245, 242, 242, 1)",
									fontWeight: "500"
								  },
													  
						   }],
						  tooltip: {
						        trigger: 'axis',
						        // axisPointer: {
						        //     type: 'cross'
						        // }
						    },
						    grid: {
						        left: '3%',
						        right: '4%',
						        bottom: '3%',
						        containLabel: true
						    },
						  
						    xAxis: {
						        type: 'category',
						        boundaryGap: false,
						        data: xArr,
								axisLabel: {
											textStyle: {
												color: '#c8c8c8',
												fontSize: '12' //字体大小设置
											}
										},
						    },
						    yAxis: {
						        type: 'value',
								splitLine: { //网络格的显示，和颜色设置
											show: true,
											lineStyle: {color: ['#0b4aaf'],}
										},
										axisLabel: {
										textStyle: {
											color: '#c8c8c8',
											fontSize: '12' //字体大小设置
										}
									},
								
						    },
						    series: [
						        {
						            name: 'CO2浓度',
						            type: 'line',
						            // stack: '总量',
									 yAxisIndex: 0,
						            data: yArr1,
									itemStyle: {
										normal: {
												color: '#c82528'
									            }
									           },
											   markLine: {
											                  silent: true,
											                  data: [{
											                      yAxis: 700,
																  lineStyle:{
																	  normal:{
																		  color:'#1e9c28'
																	  }
																  }
											                  }, {
											                      yAxis: 1000,
																  lineStyle:{
																	  normal:{
																		  color:'#e3d42e'
																	  }
																  }
											                  }, {
											                      yAxis: 1500,
																   lineStyle:{
																		  normal:{
																			  color:'#e3102d'
																		  }
																	  }
											                  }
															  ],
											                  lineStyle: {
											                      normal: {
											                      type: 'solid',
											                  },
											              },
											                  
											              }
						        }
								
								
						    ]
					};
			myChart.setOption(options)
			
			// 
			var options = {
						title: [{
							  top:'10',
						       left: 'center',
						       text: '建筑内环境记录-PM2.5',
							   textStyle: {
								   color: "rgba(245, 242, 242, 1)",
									fontWeight: "500"
								  },
													  
						   }],
						 tooltip: {
						        trigger: 'axis'
						    },
						    grid: {
						        left: '3%',
						        right: '4%',
						        bottom: '3%',
						        containLabel: true
						    },
						  
						    xAxis: {
						        type: 'category',
						        boundaryGap: false,
						        data: xArr,
								axisLabel: {
											textStyle: {
												color: '#c8c8c8',
												fontSize: '12' //字体大小设置
											}
										},
						    },
						    yAxis: {
						        type: 'value',
								splitLine: { //网络格的显示，和颜色设置
											show: true,
											lineStyle: {color: ['#0b4aaf'],}
										},
										axisLabel: {
										textStyle: {
											color: '#c8c8c8',
											fontSize: '12' //字体大小设置
										}
									},
								
						    },
						    series: [
						        {
						            name: 'PM2.5',
						            type: 'line',
									yAxisIndex: 0,
						            // stack: '总量',
						            data: yArr2,
									itemStyle: {
										normal: {
												color: '#c87b0e'
									            }
									           },
				   markLine:{
								  silent: true,
								  data: [{
									  yAxis: 75,
									  lineStyle:{
										  normal:{
											  color:'#1e9c28'
										  }
									  }
								  }, {
									  yAxis: 150,
											  lineStyle:{
												  normal:{
													  color:'#e3d42e'
												  }
											  }
								  }, 
								  // {
									 //  yAxis: 1500,
										//    lineStyle:{
										// 		  normal:{
										// 			  color:'#e3102d'
										// 		  }
										// 	  }
								  // }
										],
								  lineStyle: {
									  normal: {
									  type: 'solid',
								  },
							  },
								  
							  }
						        },
						    ]
					};
			myChart2.setOption(options)
			var options = {
						title: [{
							  top:'10',
						       left: 'center',
						       text: '建筑内环境记录-温度',
							   textStyle: {
								   color: "rgba(245, 242, 242, 1)",
									fontWeight: "500"
								  },
													  
						   }],
						 tooltip: {
						        trigger: 'axis'
						    },
						    grid: {
						        left: '3%',
						        right: '4%',
						        bottom: '3%',
						        containLabel: true
						    },
						  
						    xAxis: {
						        type: 'category',
						        boundaryGap: false,
						        data: xArr,
								axisLabel: {
											textStyle: {
												color: '#c8c8c8',
												fontSize: '12' //字体大小设置
											}
										},
						    },
						    yAxis: {
						        type: 'value',
								splitLine: { //网络格的显示，和颜色设置
											show: true,
											lineStyle: {color: ['#0b4aaf'],}
										},
										axisLabel: {
										textStyle: {
											color: '#c8c8c8',
											fontSize: '12' //字体大小设置
										}
									},
								
						    },
						    series: [
						        {
						            name: '室内温度',
						            type: 'line',
									yAxisIndex: 0,
						            // stack: '总量',
						            data: yArr4,
									itemStyle: {
										normal: {
												color: '#a528c8'
									            }
									           },
											   markLine:{
											  silent: true,
											  data: [{
												  yAxis: 18,
												  lineStyle:{
													  normal:{
														  color:'#1e9c28'
													  }
												  }
											  }, {
												  yAxis: 30,
														  lineStyle:{
															  normal:{
																  color:'#e3d42e'
															  }
														  }
											  },
											  // {
												 //  yAxis: 1500,
													//    lineStyle:{
													// 		  normal:{
													// 			  color:'#e3102d'
													// 		  }
													// 	  }
											  // }
													],
											  
										  }
						        },
						    ]
					};
			myChart3.setOption(options)
			
			var options = {
						title: [{
							  top:'10',
						       left: 'center',
						       text: '建筑内环境记录-湿度',
							   textStyle: {
								   color: "rgba(245, 242, 242, 1)",
									fontWeight: "500"
								  },
													  
						   }],
						 tooltip: {
						        trigger: 'axis'
						    },
						    grid: {
						        left: '3%',
						        right: '4%',
						        bottom: '3%',
						        containLabel: true
						    },
						  
						    xAxis: {
						        type: 'category',
						        boundaryGap: false,
						        data: xArr,
								axisLabel: {
											textStyle: {
												color: '#c8c8c8',
												fontSize: '12' //字体大小设置
											}
										},
						    },
						    yAxis: {
						        type: 'value',
								splitLine: { //网络格的显示，和颜色设置
											show: true,
											lineStyle: {color: ['#0b4aaf'],}
										},
										axisLabel: {
										textStyle: {
											color: '#c8c8c8',
											fontSize: '12' //字体大小设置
										}
									},
								
						    },
						    series: [
						     
						        {
						            name: '室内湿度',
						            type: 'line',
									yAxisIndex: 0,
						            data: yArr3,
									itemStyle: {
										normal: {
												color: '#1ec895'
									            }
									           },
							markLine:{
									  silent: true,
									  data: [{
										  yAxis: 30,
										  lineStyle:{
											  normal:{
												  color:'#1e9c28'
											  }
										  }
									  }, {
										  yAxis: 70,
												  lineStyle:{
													  normal:{
														  color:'#e3d42e'
													  }
												  }
									  },
									  // {
										 //  yAxis: 1500,
											//    lineStyle:{
											// 		  normal:{
											// 			  color:'#e3102d'
											// 		  }
											// 	  }
									  // }
											],
																		  
							}
											   									
						        }
						    ]
					};
			myChart4.setOption(options)
				},
		}
	}
</script>

<style>
	*{
		padding: 0;
		margin: 0;
	}
	.module_6{
		margin: 0 auto;
		width: 100%;
		height: 53.75rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mbbg.png) no-repeat;
		background-size: 100% 100%;
		color: #FFFFFF;
	}
	.title6{
		margin:3.125rem auto 2.5rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
		background-size: 100% 100%;
		width: 89.5rem;
		height: 5rem;
		line-height: 5rem;
		text-align: center;
		font-size:1.5rem ;
	}
	.item6{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.item-box6{
		position: relative;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
		background-size: 100% 100%;
		/* margin: 0 1.25rem; */
		text-align: center;
		    width: 25rem;
		    height: 9.0625rem;
		padding-top: 1.9rem;
	}
	.span-1{
		padding-left: 1.45rem;
	}
	.item-image6{
		position: absolute;
		   /* width: 2.25rem;
		    height: 2.25rem; */
		    line-height: 2.525rem;
		    border-radius: 50%;
		   /* border: 0.0625rem #fff solid; */
		    left: 8.5125rem;
		    top: 5.2rem;
			
	}
	.item-image6 img{
		width:1.4rem;
		height:1.5rem;
	}
	.bottom-line6{
		margin: .8rem auto;
		width:50%;
		border-bottom: 1px #fff solid;
	
	}

	.echarts-line6{
		display: flex;
		margin: 1.625rem auto 0;
		width: 100%;
		height: 28rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg1.png) no-repeat;
		background-size: 100% 100%;
	}
	
	
</style>
