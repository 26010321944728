import axios from 'axios'

axios.defaults.baseURL="https://ibest.ejoy-tech.top"//测试环境接口地址
// axios.defaults.baseURL="http://ibest.com"  //Robin本地接口地址
axios.defaults.params={}
// const ERR_OK =0 
axios.interceptors.request.use(res=>{
	if(res.url!='/api/web/login'){
		let token=localStorage.getItem("token")
		res.params.token=token
	}
	
// console.log(res,"res")
　　return res
}, function (error) {
	console.log(error,"error")
　　// 对请求错误做些什么
return Promise.reject(error)
});

axios.interceptors.response.use(res=>{ 
	// console.log(res.data,"response2")
	setTimeout(()=>{
	if(res.data.errcode==-4){
		 window.location.href='/login'
		alert("即将跳转到登录页")
	}
	
	},5000)
	// console.log(res,"zhehisha")
	return res.data
	
},error=>{
	console.log(error.response.status,"11111111111111")
	return Promise.reject(error.response.status)
})

export default axios;	
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		// const ERR_OK =0   //要先定义一个ERR_OK=0，然后才能在下面引用，
		// export function get(url) {
		// return function (params) {return axios.get(url,{
			
		// params
		// }).then((res） => {
		// const {errno,data} = res.data
		// if (errno == ERR_OK) { 
		// return data
		// }
		// }).catch(() =>{
				
		// 		}
				
				// export default axios;