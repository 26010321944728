<template>
	<div class="module_2">
		<h2>{{conditionerInfo.template_name}}</h2>
	<div class="top2">
		<div class="label2 ">
		<div class="title-img2 ">
		<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/power-off.png" alt="">
		</div>
		<span><big>{{conditionerInfo.run_status}} | {{conditionerInfo.error_status}}</big></span>
		</div>
		<!--  -->
		<div class="label2 ">
		<div class="title-img2 ">
		<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/snow.png" alt="">
		</div>
		<span><big>{{conditionerInfo.operation_status}}模式 | {{conditionerInfo.cold_hot_status}}</big></span>
		</div>
	</div>
		<div class="title2">
			<div class="label2 ">
			<div class="title-img2 ">
			<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/coldtemperature.png" alt="">
			</div>
			<span><big>供回水温度 {{conditionerInfo.gongshuiwendu}}</big><small>℃</small> | <big>{{conditionerInfo.huishuiwendu}}</big><small>℃</small></span>
			<!-- <span></span> -->
			</div>
			<!--  -->
			<div class="label2">
			<div class="title-img2  ">
			<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/leaf.png" alt="">
			</div>
			<span><big>节能率/节能量 {{conditionerInfo.jienenglv}}</big><small>%</small> | <big>{{conditionerInfo.jienengliang}}</big><small>kW</small></span>
			</div>
			<!--  -->
			<div class="label2">
			<div class="title-img2">
			<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/cop.png" alt="">
			</div>
			<span><big>系统COP {{conditionerInfo.xitongcop}}</big></span>
			<!-- <span></span> -->
			</div>
			<div class="label2">
			<div class="title-img2">
			<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/meter.png" alt="">
			</div>
			<span><big>系统实时功率 {{conditionerInfo.xitongnenghao}}</big><small>kW</small></span>
			<!-- <span></span> -->
			</div>
			</div>
		<div class="item2">
			<div class="item-box2">
				<span><big>{{conditionerInfo.cold_machine.num}}</big><small> 台</small>/<big>{{conditionerInfo.cold_machine.percent}}</big><small> %</small></span>
				<div class="bottom-line"></div>
				<div class="item-image2 img-1">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/chiller_3.png" style="width: 1.6rem;height: 1.3rem;margin-top: 4px;" alt="">
				</div>
				<!-- <span class="span-1">{{conditionerInfo.cold_machine.name}}</span> -->
				<span class="span-1">{{conditionerInfo.building_info.template_air.cold_machine_name||"冷水机组"}}</span>
			</div>
			<div class="item-box2">
					<span><big>{{conditionerInfo.cold_pump.num}}</big><small> 台</small>/<big>{{conditionerInfo.cold_pump.percent}}</big><small> %</small></span>
				<div class="bottom-line"></div>
				<div class="item-image2">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/pump.png" alt="">
				</div>
				<span class="span-1">{{conditionerInfo.building_info.template_air.cold_pump_name||"冷却水泵"}}</span>
			</div>
			<div  class="item-box2">
				<span><big>{{conditionerInfo.frozen_pump.num}}</big><small> 台</small>/<big>{{conditionerInfo.frozen_pump.percent}}</big><small> %</small></span>
				<div class="bottom-line"></div>
				<div class="item-image2">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/pump.png" alt="">
				</div>
				<span class="span-1">{{conditionerInfo.building_info.template_air.frozen_pump_name||"冷冻水泵"}}</span>
			</div>
			<div class="item-box2">
				<span><big>{{conditionerInfo.cold_tower.num}}</big><small> 台</small>/<big>{{conditionerInfo.cold_tower.percent}}</big><small> %</small></span>
				<div class="bottom-line"></div>
				<div class="item-image2">
				<img style="width: 3.2rem;height: 3.2rem; margin-top: -8px;" src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/fan.png" alt="">
				</div>
				<span class="span-1">{{conditionerInfo.building_info.template_air.cold_tower_name||"冷却塔"}}</span>
			</div>
		</div>
		<div  class="echarts-line2">
			<!-- <div id="line2" style="width: 93.75rem; height: 22rem; margin: 0 auto;"> -->
			    <el-carousel trigger="click" height="150px" direction="vertical" :autoplay="false">
			      <el-carousel-item>
			       <div id="conditioner" style="width: 93.75rem; height: 22rem; margin: -0.5rem auto;"></div>
			      </el-carousel-item>
				  <el-carousel-item>
				   <div id="conditioner2" style="width: 93.75rem; height: 22rem; margin: .8rem auto 0;"></div>
				  </el-carousel-item>
				  <el-carousel-item>
				   <div id="conditioner3" style="width: 93.75rem; height: 21rem; margin:1.2rem auto 0 ;"></div>
				  </el-carousel-item>
			    </el-carousel>
				
			</div>
		</div>
	</div>
</template>
<script>
	import {EventBus} from "/src/util/event-bus.js";
	export default{
		data() {
			return{
				id: '',// 接收
				conditionerInfo:{
					cold_machine:[],
					cold_pump:[],
					cold_tower:[],
					frozen_pump:[],
					building_info:{
						template_air:[]
					}
				},
				// chartsParam:{type:"building",period:null,only_charts:true},
			}
		},
		
		created() {
			EventBus.$on("id", (id) => {
			  // index发送来的消息
			  this.buildingId = id;
			  // console.log( this.buildingId,"22222222222222222222222222")
			 this.$axios.get('/api/web/building/water_conditioner?building_id='+this.buildingId).then(res=>{
			 	if (res.errcode == 0) {
			 	this.conditionerInfo=res.data
			 	// console.log(this.conditionerInfo,"我有数据2222222222222222222")
			 	}else{							
			 		console.log("我没有数据22222222222222222222")
			 		}
			 })
			 this.$nextTick(() => {
			 	this.echartsLine2(this.buildingId)
			 	this.get_conditioner2(this.buildingId)
			 	this.get_conditioner3(this.buildingId)
			 })
			});
				},
				
		methods:{
		echartsLine2(buildingId){
			this.$axios.get('/api/web/building/water_conditioner?building_id='+buildingId).then(res=>{
			if (res.errcode == 0) {
			let xArr1=[]
			let xArr2=[]
			let xArr3=[]
			let xArr4=[]
			// let yArr=[]
			 const yArr=['00','01', '02','03', '04', '05' , '06', '07','08','09','10', '11', '12','13','14','15','16','17','18','19','20','21','22','23'];
			//add by robin@20210903 哥来给你打个样
			let charts=res.data.chart_list
			let chartKV={}//定义一个空的chartKV
			for(var item of charts){
				//循环charts，将item赋值给chartKV，并拿到item.day，也就是我们x轴的01-24
				chartKV[item.day]=item
			}
			for(var k of yArr){
				//再次循环我们上面定义的常量yArr，我们要理解一下 循环 in of 的区别 in 循环的是索引，of循环的是item
				if(chartKV[k]){      //如果hartKV[k]存在，我们就在变量后面push添加我们要的数据
					// console.log(chartKV[k])
					xArr1.push(chartKV[k].gongshuiwendu)
					xArr2.push(chartKV[k].huishuiwendu)
					xArr3.push(chartKV[k].lengbeng_fuzailv)
					xArr4.push(chartKV[k].lengshui_fuzailv)
				}else{   //否者就是0
					xArr1.push("")
					xArr2.push("")
					xArr3.push("")
					xArr4.push("")
				}
			}
			
			
			
			// for (let item of res.data.chart_list) {
			// 	xArr1.push(item.gongshuiwendu)
			// 	xArr2.push(item.huishuiwendu)
			// 	xArr3.push(item.lengbeng_fuzailv)
			// 	xArr4.push(item.lengshui_fuzailv)
			// 	// yArr.push(item.day)
				
				
			// }
				let myChart = this.$echarts.init(document.getElementById("conditioner"))
				var options = {
					 title: [{
							top:'20',
					        left: 'center',
					        text: '系统运行负载及供回水温度',
						   textStyle: {
							   color: "rgba(245, 242, 242, 1)",
								fontWeight: "500"
							  },
					    }],
						legend: {
						    data: ['供水温度', '回水温度', '水泵负载','冰机负载率'],
							x:'center',
							y:'bottom',
							textStyle: {
										color: '#ffffff',
										fontSize: '16',
									}
						},
						tooltip: {
						    trigger: 'axis'
						},
					    xAxis: {
					        type: 'category',
					        data: yArr,
							axisLabel: {
								interval: 0, // 月份全部显示
								textStyle: {
									color: '#c8c8c8',
									fontSize: '12' //字体大小设置
								}
							},
					    },
					    yAxis: [
							{
							           type: 'value',
									   splitLine: { //网络格的显示，和颜色设置
									   			show: true,
									   			lineStyle: {
													color: ['#0b4aaf'],
												}
									   		},
											axisLabel: {
												interval: 0, // 月份全部显示
												textStyle: {
													color: '#c8c8c8',
													fontSize: '12' //字体大小设置
												}
											},
							       },
						
						 {
						            type: 'value',
						            // name: '温度',
						            splitLine:false,  //横线消失
						            // axisLabel: {
						            //     formatter: '{value} °C'
						            // },
									axisLabel: {
										formatter: '{value} °C',
										textStyle: {
											color: '#c8c8c8',
											fontSize: '12' //字体大小设置
										}
									},
									
						        },
								
						
						],
					    series: [
					        {
					            name: '供水温度',
					            type: 'line',
					            // step: 'start',
					            data: xArr1,
								yAxisIndex: 1, //通过索引0 左边轴 1右边轴
					        },
					        {
					            name: '回水温度',
					            type: 'line',
					            step: 'middle',
					            data: xArr2,
								yAxisIndex: 1,
					        },
					        {
					            name: '水泵负载',
								 symbol: "none", //去圆点
					            type: 'line',
					            step: 'end',
					            data: xArr3
					        },
							
							 {
								name: '冰机负载率',
								 symbol: "none", //去圆点
								type: 'line',
								step: 'end',
								data: xArr4
							}
					    ]
				};
		myChart.setOption(options)
		}
		})
			},
	   		// 柱形图
			get_conditioner2(buildingId){
				this.$axios.get('/api/web/building/water_conditioner?building_id='+buildingId).then(res=>{
							if (res.errcode == 0) {
							let xArr5=[]
							let xArr6=[]
							let xArr7=[]
							// let yArr=[]
							const yArr=['00','01', '02','03', '04', '05' , '06', '07','08','09','10', '11', '12','13','14','15','16','17','18','19','20','21','22','23'];
							let chart=res.data.chart_list
							let chartItem={}
							for(var item of chart){
								chartItem[item.day]=item
							}
							for(var k of yArr){
								if(chartItem[k]){      //如果hartKV[k]存在，我们就在变量后面push添加我们要的数据
									console.log(chartItem[k])
									xArr5.push(chartItem[k].cold_electric)
									xArr6.push(chartItem[k].cop)
									xArr7.push(chartItem[k].water_system_electric)
								}else{   //否者就是0
									xArr5.push("")
									xArr6.push("")
									xArr7.push("")
								}
							}
							
							// for (let item of res.data.chart_list) {
							// 	xArr5.push(item.cold_electric)
							// 	xArr6.push(item.cop)
							// 	xArr7.push(item.water_system_electric)
							// }
				let myChart = this.$echarts.init(document.getElementById("conditioner2"))
				var options = {
				    tooltip: {
				        trigger: 'axis',
				        axisPointer: {
				            type: 'cross',
				            crossStyle: {
				                color: '#999'
				            }
				        }
				    },
					title: [{
							top:'7',
					       left: 'center',
					       text: '系统运行能耗及能效',
						   textStyle: {
							   color: "rgba(245, 242, 242, 1)",
								fontWeight: "500"
							  },
												  
					   }],
					
				    legend: {
				        data: ['系统能耗', '系统供能量', '系统COP'],
						x:'center',
						y:'bottom',
						textStyle: {
									color: '#ffffff',
									fontSize: '16',
								}
				    },
				    xAxis: [
				        {
				            type: 'category',
				            data: yArr,
				            axisPointer: {
				                type: 'shadow'
				            },
							axisLabel: {
								interval: 0, // 月份全部显示
								textStyle: {
									color: '#c8c8c8',
									fontSize: '12' //字体大小设置
								}
							},
				        }
				    ],
				    yAxis: [
				        {
				            type: 'value',
				            splitLine:false,  //横线消失
				            // min: 0,
				            // max: 100,
				            // interval: 10,
							axisLabel: {
							formatter: '{value} ',
								textStyle: {
									color: '#c8c8c8',
									fontSize: '12' //字体大小设置
								}
							},
							splitLine: { //网络格的显示，和颜色设置
										show: true,
										lineStyle: {color: ['#0b4aaf'],}
									}
									
				        },
				        {
				            type: 'value',
							
				           min: 0,
				           max: 10,
				           interval: 1,
				           axisLabel: {
				           formatter: '{value} ',
				           	textStyle: {
				           		color: '#c8c8c8',
				           		fontSize: '12' //字体大小设置
				           	}
				           },
							splitLine: { //网络格的显示，和颜色设置
										show: false,
										lineStyle: {color: ['#0b4aaf'],
										}
									}
				        },
						
				    ],
				    series: [
				        {
				            name: '系统能耗', 
				            type: 'bar',
				            data: xArr7
				        },
				        {
				            name: '系统供能量',
				            type: 'bar',
				            data: xArr5
				        },
				        {
				            name:  '系统COP',
				            type: 'line',
				            yAxisIndex: 1,
				            data: xArr6
				        }
				    ]
				};
				myChart.setOption(options)
				}
				})
			},
			// 直方图
			get_conditioner3(buildingId){
				this.$axios.get('/api/web/building/water_conditioner?building_id='+buildingId).then(res => {
				if (res.errcode == 0) {
					let xArr = Object.keys(res.data.histogram)
					let yArr = Object.values(res.data.histogram)
					// let arr=[]
					// let count=0
					// let obj=res.data.histogram
					// // 首先我要循环拿到在上面定义的obj的各项index，然后给他相加
					// for(var index in obj){
					// 	count+=obj[index]
					// }
					// // 还要再次循环obj算出所占百分比,拿obj每项/相加后的总数*100
					// for(var index in obj){
					// 	// 
					// 	arr[index]=(obj[index]/count*100).toFixed()
					// }
					
					
				let myChart = this.$echarts.init(document.getElementById("conditioner3"))
			var	options = {
				tooltip: {
				    trigger: 'axis',
				    axisPointer: {
				        type: 'cross',
				        crossStyle: {
				            color: '#999'
				        }
				    }
				},
				tooltip: {
					trigger: 'item',
					formatter:"{b}<br/>({c}%)"
					 // formatter: function(v) {
						//  console.log(v,"vvvvv")
						//  // 通过打印我们知道v下面有个name，正是我们所要的那个数据，将他赋值给percent
						// var percent=arr[v.name]
						// var val = v.data;
						// return val + '</br>百分比：'+ percent +'%';
					 							
					 // },
				},
				title: [{
				       left: 'center',
				       text: '冷冻站系统效率分布',
					   textStyle: {
						   color: "rgba(245, 242, 242, 1)",
							fontWeight: "500"
						  },
											  
				   }],
				    xAxis: {
				        type: 'category',
				        data: xArr,
						axisLabel: {
							textStyle: {
								color: '#c8c8c8',
								fontSize: '12' //字体大小设置
							}
						},
				    },
				    yAxis: {
				        type: 'value',
						splitLine: { //网络格的显示，和颜色设置
									show: true,
									lineStyle: {color: ['#0b4aaf'],}
								},
								axisLabel: {
									textStyle: {
										color: '#c8c8c8',
										fontSize: '12' //字体大小设置
									}
								},
				    },
				    series: [{
						barWidth:'30',
				        data: yArr,
				        type: 'bar',
						
				    }]
				};
				myChart.setOption(options)
				}
				})
			}
			
		}
	}
</script>

<style>
	*{
		padding: 0;
		margin: 0;
	}
	.module_2{
		margin: 0 auto 0;
		width: 100%;
		height: 53rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mbbg.png) no-repeat;
		background-size: 100% 100%;
		color: #FFFFFF;
	}
	.top2{
		display: flex;
		justify-content: center;
		/* margin: 0.625rem auto;
		width: 100%; */
	}
	.top2 .label2{
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg1.png)no-repeat;
		background-size: 100% 100%;
		margin: 50px auto 0px;
		width: 28rem;
		height: 4rem;
		line-height: 4.5rem;
		text-align: center;
		font-size: 1.2rem;
	}
	.top2 .title-img2{
		left: 12.5rem;
	}
	.title2{
		margin:2.125rem auto 1.5rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg1.png)no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 4rem;
		line-height: 4rem;
		text-align: center;
		font-size:1rem ;
		display: flex;
		justify-content: center;
	}
	.label2{
		width: 33%;
		position: relative;
	}
	.title-img2{
		position: absolute;
		width: 2.5rem;
		height: 2.5rem;
		line-height:3rem ;
		border-radius: 50%;
		border:0.0625rem #fff solid;
		 background: #09337B;
		 top: -1.55rem;
		 left: 14rem;
	}

	
	.title-img2 img{
		width: 1.5rem;
		height: 1.5125rem;
	}
	.item2{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.item-box2{
		position: relative;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
		background-size: 100% 100%;
		text-align: center;
		    width: 25rem;
		    height: 8rem;
		padding-top: 1.5rem;
	}
	.item-image2{
		position: absolute;
		    line-height: 2.525rem;
		    border-radius: 50%;
		    left: 8.6rem;
		    top: 4.5rem;
	}
	.item-image2 img{
		width:2.2rem;
		height:2rem;
	}
	.bottom-line{
		margin: .8rem auto;
		width:50%;
		border-bottom: 1px #fff solid;
		opacity: .5;
	
	}

	.echarts-line2{
		margin: 1.625rem auto;
		width: 100%;
		height: 23rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg1.png)no-repeat;
		background-size: 100% 100%;
	}
</style>
