<template>
		<div class="popContainer" v-show="bool_show">
	<div class="architect">
		<div class="architect_top">
			<div class="title-top"><h1>建筑区域</h1></div>
			<div class="item-button">
				<button v-for="item in areaList[0]" :class="item.id==choiceList[0].id?'btnActive':''" :key="item.id" @click="changeArea(item,0)">{{item.title}}</button>
			</div>
			<div class="drop-down">
			
			  
			<el-select  placeholder="选择省" v-model="choiceList[1]" value-key="id" @change="changeArea(choiceList[1],1)">
			     <el-option v-for="(item,index) in areaList[1]" :label="item.title" :key="item.id" :value="item" ></el-option>
			   </el-select>
			   <el-select  placeholder="选择市" v-model="choiceList[2]" value-key="id" @change="changeArea(choiceList[2],2)">
			        <el-option v-for="(item,index) in areaList[2]" :label="item.title" :key="item.id"  :value="item"></el-option>
			      </el-select>
			   
			  <el-select placeholder="选择区" v-model="choiceList[3]"  value-key="id" @change="changeArea(choiceList[3],3)">
			      <el-option v-for="(item,index) in areaList[3]" :label="item.title" :key="item.id" :value="item"></el-option>
			    </el-select>
			
			</div>
		</div>
		<!-- 建筑类别 -->
		<div>
			<div class="title-top" style="margin-top: 2.5rem;"><h1>建筑类别</h1></div>
			   
			<div class="button-2" >
				<!-- <button v-for="(item,index) in category.data" :key="item.id" :class="{active:active==item.id}" @click="selected(item.id,item.title)">{{item.title}}</button> -->
				<button v-for="(item,index) in category.data" @click="selected(item.id,item.title)"
				 :class="selected_category_id==item.id?'active':''"
				 >{{item.title}}</button>
			</div>
			<div class="footer">
				<button style="background-color:#096AC5;" @click="submit();">确认</button>
				<button style="background-color:#2B4054;" @click="close()">取消</button>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	 export default {
		name:'template-architect',
		props:{
            areaList:{
		        type:Array,
				default:()=>[[{id:0}],[],[],[],[]]
			},
			selectedList:{
		        type:Array,
				default:()=>[{id:0,title:"大区1",map_image:""},{id:0,title:"省",map_image:""},{id:0,title:"市",map_image:""},{id:0,title:"区",map_image:""}]
			}
		},
	    data() {
	      return {
			 bool_show: false,
		    dialogVisible: false,
              // 当前已选择的区域数据【大区，省，市，区】
              // choiceList:[{id:0,title:"中国",map_image:""},{id:0,title:"省",map_image:""},{id:0,title:"市",map_image:""},{id:0,title:"区",map_image:""}],
              // //:对应的省市区的下拉列表数据【大区，省，市，区】
              // areaList:[[],[],[],[],[]],
              choiceList:[{},{},{},{}],
			//建筑区域
			area:{},
			province:{},   //省
			city:{} , //市
			region:{}   ,//区
			// 建筑类别
			category: {
				data:[],
			},
			btnActive:'',
			active:0 ,//点击高亮
			selected_category_id:null,
			
	      }
	    },
		mounted() {
			// 建筑类别
			this.construction_type()
            this.choiceList=JSON.parse(JSON.stringify(this.selectedList))
		},
		methods:{
				changeArea(areaObj,level){
				    this.$set(this.choiceList,level,{id:areaObj.id,title:areaObj.title,map_image:areaObj.map_image})
					// this.choiceList[level]={id:areaObj.id,title:areaObj.title,map_image:areaObj.map_image}
					for(let i=level+1;i<=3;i++){
					    this.choiceList[i]={}
						this.areaList[i+1]=[]
					}
					console.log(this.choiceList,level)
                    if(level<3){
                        this.$axios.get('/api/web/area/get_list_by_id?area_id='+areaObj.id).then(res=>{
							if(res.errcode == 0){
                                this.$set(this.areaList,level+1,res.data)

							}
						})
					}

				},
				// 点击当前按钮高亮
				// selected(id,title){
				// 	this.active=id
				// 	this.title=title
				// },
				selected(id,title) {
					if(this.selected_category_id==id){
						this.selected_category_id=null
						this.title=null
					}else{
						this.selected_category_id=id
						this.title=title
					}
				},
				
				// 建筑类别
				construction_type(id) {
					this.$axios.get('/api/web/building_category/list?a=2')
						.then(res => {
							if (res.errcode == 0) {
								this.category.data = res.data;
								console.log(this.category.data,"this.category")
							}
						})
				},
				empty(){
					this.active=[]
				},
				// 提交
				show(submit,close) {
						this.bool_show = true;
					},
				submit(areaList,title){
					// if(this.title){
					this.$emit('selectedListChange',JSON.parse(JSON.stringify(this.choiceList)),this.selected_category_id)
					this.$emit('areaListChange',this.areaList,this.title)
					console.log(this.selected_category_id,"这是啥啥啥啥这是啥啥啥啥")
					this.bool_show = false;
					if(this.confirmBack){
						this.confirmBack();
					}
					// }
					// else{
					// 	 this.$message({
					// 	          message:'未选择建筑类别',
					// 	        });
					// }
					
				},
				// 点击按钮关闭
				close() {
					this.bool_show = false;
					if(this.cancelBack){
					this.cancelBack();
					}

				},

		}
		
	  }
</script>

<style>
	.popContainer{
		 position: fixed;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    z-index: 9;
		    background: rgba(0,0,0,0.4);
			display: flex;
			justify-content: center;
			align-items: center;
	}
.architect{
	z-index: 10;
		color: #FFFFFF;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/%E7%9F%A9%E5%BD%A2%E5%A4%87%E4%BB%BD%2039.png) no-repeat;
		background-size: 100% 100%;
		width: 59.25rem;
		height: 48.1875rem;
		z-index:10;
		position: fixed;

	}
	.active{
	}
	.btnActive{
		background:#096AC5;
	}
	/* .currents{
		background:#096AC5;
	} */
	.architect_top{
		width: 100%;
		padding-top: 10px;
	}
	.title-top{
		width:13.625rem;
		height: 4.0625rem;
		line-height: 4.0625rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/tob.png) no-repeat;
		background-size: 100% 100%;
		 margin: 50px  auto 1rem;
		text-align: center;
	}
	.title-top h1{
		font-size: 1.875rem;
		background-image: -webkit-linear-gradient(bottom, #59C8FF, #FAFEFF);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.item-button{
		width: 100%;
		text-align: center;
		margin: 1rem auto;
	}
.drop-down{
	display: flex;
	justify-content: center;
	align-items: center;
}
.el-select{
	margin: 0.625rem;
	width:10.6875rem;
	height: 3.0625rem;
}
.el-select input{
	background-color:#0A4073;
	outline: none;
	border: none;
	color: #fff;
	text-align: center;
}
.button-2{
	text-align: center;
	width:49.5625rem;
	height: 10.5rem;
	border: 0.125rem #004892 solid;
	border-radius: 1.4375rem;
	margin:  2.625rem auto;
}
.footer{
	text-align: center;
}
.footer button{
	margin: 10px 60px;
}
</style>
