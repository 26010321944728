<template>
	<div class="atmosphere">
		<h2>{{buildingInfo.template_name}}</h2>
		<div class="item1">
			<div class="item-box1">
				<span><big>{{buildingInfo.day}}</big><small> m³</small></span>
				<div class="bottom-line1"></div>
				<div class="item-image1">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/gas1.png" alt="">
				</div>
				<span class="span-1">今日用气</span>
			</div>
			<div class="item-box1">
				<span><big>{{buildingInfo.month}}</big><small> m³</small></span>
				<div class="bottom-line1"></div>
				<div class="item-image1">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/gas2.png" alt="">
				</div>
				<span class="span-1">本月用气</span>
			</div>
			<div  class="item-box1">
				<span><big>{{buildingInfo.year}}</big><small> m³</small></span>
				<div class="bottom-line1"></div>
				<div class="item-image1">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/GAS4.png" alt="" style="width: 1.2rem;height: 1.5rem;margin-top: 3px;">
				</div>
				<span class="span-1">今年用气</span>
			</div>
			<div class="item-box1">
				<span><big>{{buildingInfo. areaPerYear}}</big><small> m³/(m².y)</small></span>
				<div class="bottom-line1"></div>
				<div class="item-image1">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/005.png" alt="" style="margin-left: -20px;">
				</div>
				<span class="span-1">年单位面积用气</span>
			</div>
			<div class="item-box1">
				<span><big>{{buildingInfo.peoplePerYear}}</big><small> m³(r.y)</small></span>
				<div class="bottom-line1"></div>
				<div class="item-image1">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/006.png" alt="">
				</div>
				<span class="span-1">年人均用气</span>
			</div>
		</div>
		
		
		<div  class="echarts-line1">
			<div class="time1">
			<div class="time-item1">
				<span v-for="(item,index) in periodList" :key="index" @click="changePeriod(index)" :class="index==selectedPeriodIndex?'active':''" >{{item.label}}</span>
			</div>
			 <el-select v-model="chartsParam.type" placeholder="" @change="renderCharts">
			    <el-option
			      v-for="item in chartsTypeList"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			  </el-select> 
			  </div>
			<div id="atmosphere_echarts" style="width: 93.75rem; height: 23rem; margin: 0 auto;">
			</div>
			<!-- <div style="text-align: center; font-size: 1.3rem;">苏州湾大厦2020总建筑用水量</div> -->
		</div>
	</div>
</template>

<script>
	import {
		 EventBus 
	} from "/src/util/event-bus.js";
	export default{
		data() {
		  return {
			  chartsTypeList:[{value:"building",label:"建筑总量"},{value:"people",label:"人均用气"},{value:"area",label:"单位面积"}],
              chartsParam:{type:"building",period:null,only_charts:true,template_type:"atmosphere"},

              periodList:[{value:"hour",label:"时"},{value:"day",label:"天"},{value:"month",label:"月"},{value:"year",label:"年"}],
              selectedPeriodIndex:2,
			  buildingInfo:"",
			  calculate:null,
			  // 
			  id: ''	
		  }
		},
		created() {
			EventBus.$on("id", (id) => {
			  // index发送来的消息
			  this.buildingId = id;
			  console.log( this.buildingId,"id1111111111111111")
						this.$axios.get('/api/web/electric?building_id='+this.buildingId +'&template_type=atmosphere').then(res=>{
							if (res.errcode == 0) {
							this.buildingInfo=res.data
							console.log(this.buildingInfo,"我有数据")
							}else{							
								console.log("我没有数据")
								}
						})
						this.changePeriod(1)
						this.renderCharts()
			});
				},
				methods:{
		    		changePeriod(index){
                        if(this.selectedPeriodIndex!=index){
                            this.selectedPeriodIndex=index
                            this.renderCharts()
						}
					},
					renderCharts(){
						// debugger
                        this.chartsParam.period=this.periodList[this.selectedPeriodIndex].value
                         this.$axios.post('/api/web/electric?building_id='+this.buildingId,this.chartsParam).then(res=>{
							if(res.errcode == 0){
								this.get_atmosphere(res.data.charts);
							}
						})
					},
					get_atmosphere(data){
                        let xArr=Object.keys(data)
                        let yArr=Object.values(data)
                        // console.log(xArr,yArr)
                        let EchartsDom = this.$echarts.init(document.getElementById("atmosphere_echarts"))
                        let option = {
                            xAxis: {
                                type: 'category',
                                data: xArr,
								axisLabel: {
									textStyle: {
										color: '#c8c8c8',
										fontSize: '12' //字体大小设置
									}
								},
                            },
							tooltip: {
							    trigger: 'axis'
							},
                            yAxis: {
                                type: 'value',
								splitLine: { //网络格的显示，和颜色设置
											show: true,
											lineStyle: {color: ['#0b4aaf'],}
										},
										axisLabel: {
											textStyle: {
												color: '#c8c8c8',
												fontSize: '12' //字体大小设置
											}
										},
                            },
                            series: [{
								barWidth:'20',
                                data: yArr,
                                type: 'bar',
                                smooth: true,
								itemStyle: {
									normal: {
											color: '#a52250'
								            }
								           },
								
								
								
                            }]
                        }
                        EchartsDom.setOption(option)
					},
					
				}
	}
</script>

<style lang="scss">
	*{
		padding: 0;
		margin: 0;
	}
	big{
		font-size: 1.3rem;
		font-weight: 500;
	}
	.atmosphere{
		margin: 0 auto 0;
		width: 100%;
		// height: 53rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mbbg.png) no-repeat;
		background-size: 100% 100%;
		color: #FFFFFF;
	
	.item1{
		margin-top: 5.875rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.item-box1{
		position: relative;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
		background-size: 100% 100%;
		/* margin: 0 1.25rem; */
		text-align: center;
		    width: 25rem;
		    height: 9.0625rem;
		padding-top: 1.8rem;
	}
	.item-image1{
		position: absolute;
		   /* width: 2.25rem;
		    height: 2.25rem; */
		    line-height: 2.525rem;
		    border-radius: 50%;
		   /* border: 0.0625rem #fff solid; */
		    left: 8.3125rem;
		    top: 5.7125rem;
	}
	.item-image1 img{
		width:1.0rem;
		height:1.2rem;
	}
	.bottom-line1{
		margin: 1.27rem auto;
		width:50%;
		border-bottom: 0.0625rem #fff solid;
		opacity: .5;
	
	}

		.time1{
			padding-top: 20px;
			margin: 10px auto 0;
			display: flex;
			justify-content: space-between;
			width: 80%;
			}
			.time-item1 span{
				background-color: #071D3C;
				margin: 0 0.1625rem;
				display: inline-block;
				width:2.625rem;
				height:2.625rem;
				color: #696969;
				line-height: 2.625rem;
				text-align: center;
				
			}
			.el-select{
				/* margin: 0.625rem; */
				width:7.3125rem;
				height: 2.625rem;
			}
			.el-select input{
				background: #0C9FE8;
				outline: none;
				border: none;
				color: #fff;
				text-align: center;
			}
			.time-item1 .active{
				background-color:#0C9FE8;
				color: #fff;
			}
	.echarts-line1{
		margin: 1rem auto 0;
		width: 100%;
		height: 30rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg1.png) no-repeat;
		background-size: 100% 100%;
	}
	}
</style>
