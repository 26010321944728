<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
	data() {
		return{
			windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
			windowHeight: document.documentElement.clientHeight, //实时屏幕高度
		}
	},
	mounted() {
		// 跟随窗口大小自适应宽高
		const that=this
		window.onresize = () => {
			return (() => {
				window.fullHeight = document.documentElement.clientHeight;
				window.fullWidth = document.documentElement.clientWidth;
				that.windowHeight = window.fullHeight; // 高
				that.windowWidth = window.fullWidth; // 宽console.log("实时屏幕高度：", that.windowHeight);
				console.log("实时屏幕宽高：", that.windowWidth,that.windowHeight);
				var $_canvas = document.getElementById("app");
				   $_canvas.style.width = that.windowWidth + "px";
				   $_canvas.style.height = that.windowHeight + "px";
			})()
		};
	}
};
</script>
<style lang="scss">
* { margin: 0;
 padding: 0;
  box-sizing: border-box;
  }
		h1{
			font-size: 3rem;
			// border: 1px solid #000000;
		}
		h2{
			padding-top:.5rem;
			text-align: center;
			background-image: -webkit-linear-gradient(bottom, #59C8FF, #FAFEFF);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-size: 2rem;
		}
		a {
			color: #FFFFFF;
			text-decoration: none;
			font-weight: bold;
		}
		
		i {
			// background-image: -webkit-linear-gradient(bottom, #FAFF67, #FFFFFF);
			// -webkit-background-clip: text;
			// -webkit-text-fill-color: transparent;
			color: #FFFFFF;
		}
		button {
			width:144px;
			height: 3.0625rem;
			background: #0B3F73;
			border-radius: 0.3125rem;
			color: #FFFFFF;
			font-size:1.375rem;
			outline: none;
			box-shadow: none;
			border: none;
			margin: 1.2rem 1rem 0.5rem;
		}
		
		
</style>
