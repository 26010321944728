<template>
	<div class="module_4">
		<h2>{{lightInfo.template_name}}</h2>
		<div class="top4">
		<div class="title4">
			<div class="title-img4">
				<img src="../../assets/atom.png" >
			</div>
			<big>{{lightInfo.mode}}模式</big>
			</div>
		<div class="title4">
			<div class="title-img4">
			<!-- <img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/shiyonggaikuangtongji.png" alt=""> -->
			<img src="../../assets/sense.png" >
			
			</div>
			<big>{{lightInfo.work_scene}}场景</big></div>
		</div>
		<div class="item4">
			<div class="item-box4">
				<span><big>{{lightInfo.online_machine_num}}</big><small> 台</small>/<big>{{lightInfo.online_machine_percent}}</big><small> %</small></span>
				<div class="bottom-line4"></div>
				<div class="item-image4">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/link.png" alt="">
				</div>
				<span class="span-1">设备在线数量</span>
			</div>
			<div class="item-box4">
				<span><big>{{lightInfo.run_machine_num}}</big><small> 台</small>/<big>{{lightInfo.run_machine_percent}}</big><small> %</small></span>
				<div class="bottom-line4"></div>
				<div class="item-image4">
				<img src="../../assets/light-on.png" alt="">
				</div>
				<span class="span-1">开启数量</span>
			</div>
			<div  class="item-box4">
				<span><big>{{lightInfo.run_hours}}</big><small> 小时</small></span>
				<div class="bottom-line4"></div>
				<div class="item-image4">
				<img src="../../assets/clock.png" alt="">
				</div>
				<span class="span-1">今日开启时间</span>
			</div>
			<!-- <div class="item-box4">
				<span><big>{{lightInfo.average_run_hours}}</big><small> 小时</small></span>
				<div class="bottom-line4"></div>
				<div class="item-image4">
				<img src="../../assets/moxingyunhang.png" alt="">
				</div>
				<span class="span-1">平均开启时间</span>
			</div> -->
		</div>
		<div  class="echarts-line4">
			<div id="line4" style="width: 93.75rem; height: 26rem; margin: 0 auto;">
				
			</div>
		</div>
	</div>
</template>

<script>
	import {EventBus} from "/src/util/event-bus.js";
	export default{
		data() {
			return{
				id: '',// 接收
				lightInfo:{
					building_info:[]
				},
			}
		},
		created() {
			EventBus.$on("id", (id) => {
			  // index发送来的消息
			  this.buildingId = id;
			  // console.log( this.buildingId,"33333333333333")
			  // this.get_lightInfo(this.buildingId)
				this.echartsLine4(this.buildingId)
			});
				},
		methods:{
			// get_lightInfo(buildingId){
			// 				this.$axios.get('/api/web/building/light?building_id='+buildingId).then(res=>{
			// 					// debugger
			// 					if (res.errcode == 0) {
			// 					this.lightInfo=res.data
			// 					console.log(this.lightInfo,"我有数据照明。。。。。。")
			// 					}else{
			// 						console.log("我没有数据333333333333")
			// 						}
			// 				})
			// 			},
			echartsLine4(buildingId){
				this.$axios.get('/api/web/building/light?building_id='+buildingId).then(res=>{
				if (res.errcode == 0) {
					this.lightInfo=res.data
				let xArr=[]
				const yArr=['00','01', '02','03', '04', '05' , '06', '07','08','09','10', '11', '12','13','14','15','16','17','18','19','20','21','22','23'];
				let charts=res.data.chart
				let chartKV={}//定义一个空的chartKV
				for(var item of charts){
					//循环charts，将item赋值给chartKV，并拿到item.day，也就是我们x轴的01-24
					chartKV[item.day]=item
				}
				for(var k of yArr){
					//再次循环我们上面定义的常量yArr，我们要理解一下 循环 in of 的区别 in 循环的是索引，of循环的是item
					if(chartKV[k]){      //如果hartKV[k]存在，我们就在变量后面push添加我们要的数据
						// console.log(chartKV[k])
						xArr.push(chartKV[k].run_machine_num)
						
					}else{   //否者就是0
						xArr.push("")
					}
				}
				// for (let item of res.data.chart) {
				// 	xArr.push(item.run_machine_num)
				// }
					let myChart = this.$echarts.init(document.getElementById("line4"))
					var options = {
						  
						     tooltip: {
						         trigger: 'axis'
						     },
						    title: [{
						    		top:'20',
						           left: 'center',
						           text: '公区照明',
						    	   textStyle: {
						    		   color: "rgba(245, 242, 242, 1)",
						    			fontWeight: "500"
						    		  },
						    							  
						       }],
						     grid: {
						         left: '3%',
						         right: '4%',
						         bottom: '3%',
						         containLabel: true
						     },
						     
						     xAxis: {
						         type: 'category',
						         data: yArr,
								 axisLabel: {
								 	textStyle: {
								 		color: '#c8c8c8',
								 		fontSize: '12' //字体大小设置
								 	}
								 },
						     },
						     yAxis: {
						         type: 'value',
								 splitLine: { //网络格的显示，和颜色设置
								 			show: true,
								 			lineStyle: {color: ['#0b4aaf'],}
								 		},
										axisLabel: {
											textStyle: {
												color: '#c8c8c8',
												fontSize: '12' //字体大小设置
											}
										},
						     },
						     series: [
						         {
						             // name: 'Step Start',
						             type: 'line',
						             step: 'start',
						             data:xArr,
									 itemStyle: {
									 	normal: {
									 			color: '#7ca51c'
									             }
									            },
						         }
						     ]
					};
			myChart.setOption(options)
			}
			})
				},
		}
	}
</script>

<style>
	*{
		padding: 0;
		margin: 0;
	}
	.module_4{
		margin: 0 auto;
		width: 100%;
		height: 53.75rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mbbg.png) no-repeat;
		background-size: 100% 100%;
		color: #FFFFFF;
	}
	
	.top4{
		display: flex;
		margin: 30px auto;
	}
	
	.title4{
		position: relative;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
		background-size: 100% 100%;
		margin: 10px auto;
		width: 28rem;
		height: 4rem;
		line-height: 5rem;
		text-align: center;
		font-size:1.2rem ;
		
	}
	.title-img4{
		position: absolute;
		width: 3rem;
		height: 3rem;
		line-height: 3.5rem ;
		border-radius: 50%;
		border:1px #fff solid;
		top: -1.5625rem;
		 left: 12.5rem;
		 background: #09337B;
		
	}
	.title-img4 img{
		width: 1.8rem;
		height: 1.5125rem;
	}
	.item4{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.item-box4{
		position: relative;
		text-align: center;
		   width: 26.5rem;
		    height: 8.0625rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
		background-size: 100% 100%;
		padding-top: 1.5rem;
	}
	.item-image4{
		position: absolute;
		   /* width: 2.25rem;
		    height: 2.25rem; */
		    line-height: 2.525rem;
		    border-radius: 50%;
		    left: 9.3rem;
		    top: 4.5rem;
	}
	.item-image4 img{
		width: 1.5rem;
		height:1.5rem;
	}
	.bottom-line4{
		margin: .7rem auto;
		width: 55%;
		border-bottom: 1px #fff solid;
	
	}

	.echarts-line4{
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg1.png)no-repeat;
		background-size: 100% 100%;
		margin: 1.625rem auto;
		width: 100%;
		height: 28rem;
	}
</style>
