<template>
	<div class="module_5">
		<h2>{{windInfo.template_name}}</h2>
		<div class="top5">
		<div class="title5">
			<div class="title-img5">
			<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/power-off.png" alt="">
			</div>
			<big>{{windInfo.error_status}} | {{windInfo.run_status}}</big>
			</div>
		<div class="title5">
			<div class="title-img5">
			<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/weater.png" style="width: 2.6rem;height: 2.3rem;margin-top: 5px;" alt="">
			</div>
			<big>室外环境 {{windInfo.TA_OUT}}</big><small>℃</small> | <big>{{windInfo.TH_OUT}}</big><small>%</small> | <big>
			{{windInfo.CC2_OUT}}</big><small>ppm</small> | <big>{{windInfo.PML_OUT}}</big><small>ug/m³</small>
			
			</div>
		</div>
		<div class="item5">
			<div class="item-box5">
				<span><big>{{windInfo.fan_num}}</big><small> 台</small> / <big>{{windInfo.fan_percent}}</big><small> %</small></span>
				<div class="bottom-line5"></div>
				
				<div class="item-image5">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/ahu.png" style="width: 2rem;height: 1.8rem;" alt="">
				</div>
				<span style="padding-left: 1.875rem; font-weight: 200;">{{windInfo.building_info.template_wind.fan_name||"新风机组"}}</span>
			</div>
			<div class="item-box5">
					<span><big>{{windInfo.TA_FAU_SF}}</big><small> ℃</small></span>
				<div class="bottom-line5"></div>
				<div class="item-image5">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/temperature.png" alt="">
				</div>
				<span style="padding-left: 1.875rem;font-weight: 200;">送风速度/楼内温度</span>
			</div>
			<div  class="item-box5">
				<span><big>{{windInfo.CC2_ALL}}</big><small> ppm</small> / <big>{{windInfo.PML_ALL}}</big><small> ug/m³</small> </span>
				<div class="bottom-line5"></div>
				<div class="item-image5">
				<img src="../../assets/air.png" alt="">
				</div>
				<span style="padding-left: 1.875rem;font-weight: 200;">楼内CO2/PM2.5</span>
			</div>
		</div>
		
		
		<div  class="echarts-line5">
			<!-- <div id="line5" style="width: 95.75rem; height: 26rem; margin: 0 auto;"> -->
			<el-carousel trigger="click" height="150px" direction="vertical" :autoplay="false">
			  <el-carousel-item>
			   <div id="wind1" style="width: 93.75rem; height: 22rem; margin:2rem auto 0 ;"></div>
			  </el-carousel-item>
			  <el-carousel-item>
			   <div id="wind2" style="width: 93.75rem; height: 22rem; margin:2rem auto 0 ;"></div>
			  </el-carousel-item>
			</el-carousel>
				
			</div>
		</div>
	</div>
</template>

<script>
	import {EventBus} from "/src/util/event-bus.js";
	export default{
		data() {
			return{
				id: '',// 接收
				windInfo:{
					building_info:{
						template_wind:[]
					}
				},
			}
		},
		
		created() {
			EventBus.$on("id", (id) => {
			  // index发送来的消息
			  this.buildingId = id;
			  // console.log( this.buildingId,"33333333333333")
				// this.get_wind(this.buildingId)
				// this.wind_echarts1(this.buildingId)
				this.$nextTick(() => {
								this.wind_echarts1(this.buildingId)
								this.wind_echarts2(this.buildingId)
							})
			});
			
			
				},
		
		
		methods:{
			
			wind_echarts1(buildingId){
				this.$axios.get('/api/web/building/wind?building_id='+buildingId).then(res=>{
				if (res.errcode == 0) {
					this.windInfo=res.data
				let xArr1=[]
				let xArr2=[]
				// let yArr=[]
				const yArr=['00','01', '02','03', '04', '05' , '06', '07','08','09','10', '11', '12','13','14','15','16','17','18','19','20','21','22','23'];
				let charts=res.data.chart_data
				let chartKV={}//定义一个空的chartKV
				for(var item of charts){
					//循环charts，将item赋值给chartKV，并拿到item.day，也就是我们x轴的01-24
					chartKV[item.hour]=item
				}
				for(var k of yArr){
					//再次循环我们上面定义的常量yArr，我们要理解一下 循环 in of 的区别 in 循环的是索引，of循环的是item
					if(chartKV[k]){      //如果hartKV[k]存在，我们就在变量后面push添加我们要的数据
						// console.log(chartKV[k])
						xArr1.push(chartKV[k].fan_num)
						xArr2.push(chartKV[k].fan_temperature)
					}else{   //否者就是0
						xArr1.push("")
						xArr2.push("")
						
					}
				}
				// for (let item of res.data.chart_data) {
				// 	// debugger
				// 	xArr1.push(item.fan_num)
				// 	xArr2.push(item.fan_temperature)
				// 	// yArr.push(item.hour)
				// }
				
					let myChart = this.$echarts.init(document.getElementById("wind1"))
					var options = {
						title: [{
							  top:'10',
						       left: 'center',
						       text: '新风系统运行状态',
							   textStyle: {
								   color: "rgba(245, 242, 242, 1)",
									fontWeight: "500"
								  },
													  
						   }],
						   legend: {
						       data: ['新风机组台数', '新风送风温度'],
						   	x:'center',
						   	y:'bottom',
						   	textStyle: {
						   				color: '#ffffff',
						   				fontSize: '16',
						   			}
						   },
						 tooltip: {
						        trigger: 'axis'
						    },
						    xAxis: {
						        type: 'category',
						        boundaryGap: false,
						        data:yArr,
								axisLabel: {
									textStyle: {
										color: '#c8c8c8',
										fontSize: '12' //字体大小设置
									}
								},
								},
						    yAxis: {
						        type: 'value',
								splitLine: { //网络格的显示，和颜色设置
											show: true,
											lineStyle: {color: ['#0b4aaf'],},
									// axisLabel: {
									// 		textStyle: {
									// 			color: '#c8c8c8',
									// 			fontSize: '12' //字体大小设置
									// 		}
									// 		},
										},
										axisLabel: {
											textStyle: {
												color: '#c8c8c8',
												fontSize: '12' //字体大小设置
											}
										},
								
						    },
						    series: [
						        {
						            name: '新风机组台数',
						            type: 'line',
						            stack: '总量',
						            data:xArr1
						        },
						        {
						            name: '新风送风温度',
						            type: 'line',
						            stack: '总量',
						            data: xArr2
						        }
						      
						    ]
					};
			myChart.setOption(options)
			}
			})
				},
				wind_echarts2(buildingId){
					this.$axios.get('/api/web/building/wind?building_id='+buildingId).then(res=>{
					if (res.errcode == 0) {
					let xArr4=[]
					let xArr3=[]
					// let yArr2=[]
					const yArr2=['01', '02','03', '04', '05' , '06', '07','08','09','10', '11', '12','13','14','15','16','17','18','19','20','21','22','23','00'];
					for (let item of res.data.chart_data) {
						// debugger
						xArr4.push(item.fan_num)
						xArr3.push(item.co2)
						// yArr2.push(item.hour)
					}
					
						let myChart = this.$echarts.init(document.getElementById("wind2"))
						var options = {
							title: [{
								  top:'10',
							       left: 'center',
							       text: '新风及室内环境',
								   textStyle: {
									   color: "rgba(245, 242, 242, 1)",
										fontWeight: "500"
									  },
														  
							   }],
							 tooltip: {
							        trigger: 'axis'
							    },
							    grid: {
							        left: '3%',
							        right: '4%',
							        bottom: '3%',
							        containLabel: true
							    },
							  
							    xAxis: {
							        type: 'category',
							        boundaryGap: false,
							        data:yArr2,
									axisLabel: {
										textStyle: {
											color: '#c8c8c8',
											fontSize: '12' //字体大小设置
										}
									},
							    },
							    yAxis: {
							        type: 'value',
									splitLine: { //网络格的显示，和颜色设置
												show: true,
												lineStyle: {color: ['#0b4aaf'],}
											},
											axisLabel: {
												textStyle: {
													color: '#c8c8c8',
													fontSize: '12' //字体大小设置
												}
											},
							    },
							    series: [
							        {
							            name: '新风机组台数',
							            type: 'line',
							            stack: '总量',
							            data:xArr4
							        },
							        {
							            name: 'co2',
							            type: 'line',
							            stack: '总量',
							            data: xArr3
							        }
							      
							    ]
						};
				myChart.setOption(options)
				}
				})
					}
		}
	}
</script>

<style lang="scss">
	*{
		padding: 0;
		margin: 0;
	}
	.module_5{
		margin: 0 auto;
		width: 100%;
		height: 53.75rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mbbg.png) no-repeat;
		background-size: 100% 100%;
		color: #FFFFFF;
	}
	
	.top5{
		display: flex;
		margin: 30px auto;
	}
	
	.title5{
		position: relative;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
		background-size: 100% 100%;
		margin: 10px auto;
		width: 28rem;
		height: 4rem;
		line-height: 5rem;
		text-align: center;
		font-size:1.2rem ;
		
	}
	.title-img5{
		position: absolute;
		width: 3rem;
		height: 3rem;
		line-height: 3.5rem ;
		border-radius: 50%;
		border:1px #fff solid;
		top: -1.5625rem;
		 left: 12.5rem;
		 background: #09337B;
		
	}
	.title-img5 img{
		width: 1.5rem;
		height: 1.44rem;
	}
	.item5{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.item-box5{
		position: relative;
		/* margin: 0 .5rem; */
		text-align: center;
		    width: 26.5rem;
		    height: 8.0625rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
		background-size: 100% 100%;
		padding-top: 1.7rem;
		
	
	}
	.item-image5{
		position: absolute;
		    width: 2.25rem;
		    height: 2.25rem;
		    line-height: 2.8rem;
		    border-radius: 50%;
		    /* border: 0.0625rem #fff solid; */
		    left: 8rem;
		    top: 4.5rem;
	}
	.item-image5 img{
		width: 1.5rem;
		height:1.3rem;
	}
	.bottom-line5{
		margin: .7rem auto;
		width: 55%;
		border-bottom: 1px #fff solid;
	
	}

	.echarts-line5{
		margin: 1.625rem auto;
		width: 100%;
		height: 28rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg1.png) no-repeat;
		background-size: 100% 100%;
	}
</style>
