<template>
	<div class="module_4">
		<h2>{{hotInfo.building_info.short_name}}热源</h2>
		<div class="top4">
		<div class="title4">
			<div class="title-img4">
			<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/power-off.png" alt="">
			</div>
			<big>{{hotInfo.run_status}} | </big> {{hotInfo.error_status}}  
			</div>
		<div class="title4">
			<div class="title-img4">
			<img src="../../assets/hot-temp.png" alt="">
			</div>
			<big>供回水温度 {{hotInfo.gongshuiwendu}}</big><small>℃</small> | <big>{{hotInfo.huishuiwendu}}</big><small>℃</small>
			</div>
		</div>
		<div class="item4">
			<div class="item-box4">
				<span><big>{{hotInfo.guolu_num}}</big><small> 台</small> / <big>{{hotInfo.guolu_percent}}</big><small> %</small></span>
				<div class="bottom-line4"></div>
				<div class="item-image4" style="left: 10.7rem;">
				<img src="../../assets/boiler.png" alt="" style="height: 1.2rem;margin-left: -1rem;">
				</div>
				<span class="span-1">{{hotInfo.building_info.template_hot.boiler_name||"锅炉"}}</span>
			</div>
			<div  class="item-box4">
				<span><big>{{hotInfo.reshuibeng_num}}</big><small> 台</small> / <big>{{hotInfo.reshuibeng_percent}}</big><small> %</small></span>
				<div class="bottom-line4"></div>
				<div class="item-image4">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/pump.png" style="width: 2.5em;height: 2rem;" alt="">
				</div>
				<span class="span-1">{{hotInfo.building_info.template_hot.hot_name||"热水循环泵"}}</span>
			</div>
			<div class="item-box4">
				<span><big>{{hotInfo.system_hot}}</big><small> KWh</small></span>
				<div class="bottom-line4"></div>
				<div class="item-image4">
				<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/meter.png" style="width: 1.5em; height: 1.5em;" alt="">
				</div>
				<span class="span-1">系统供热量</span>
			</div>
		</div>
		
		
		<div  class="echarts-source">
			<div id="source" style="width: 93.75rem; height: 26rem; margin: 0 auto;">
				
			</div>
		</div>
	</div>
</template>

<script>
	import {EventBus} from "/src/util/event-bus.js";
	export default{
		data() {
			return{
				id: '',// 接收
				hotInfo:{
					building_info:{
						template_hot:[]
					}
				},
			}
		},
		created() {
			EventBus.$on("id", (id) => {
			  // index发送来的消息
			  this.buildingId = id;
			  // console.log( this.buildingId,"33333333333333")
				// this.get_hot(this.buildingId)
				this.get_source(this.buildingId)
			});
				},
		methods:{
			// 图表
			get_source(buildingId){
					this.$axios.get('/api/web/building/hot?building_id='+buildingId).then(res=>{
								if (res.errcode == 0) {
									this.hotInfo=res.data
								let xArr1=[]
								let xArr2=[]
								let xArr3=[]
								const yArr=['00','01', '02','03', '04', '05' , '06', '07','08','09','10', '11', '12','13','14','15','16','17','18','19','20','21','22','23'];
							let charts=res.data.chart_data
							let chartKV={}//定义一个空的chartKV
								for(var item of charts){
									//循环charts，将item赋值给chartKV，并拿到item.day，也就是我们x轴的01-24
									chartKV[item.hour]=item
								}
								for(var k of yArr){
									//再次循环我们上面定义的常量yArr，我们要理解一下 循环 in of 的区别 in 循环的是索引，of循环的是item
									if(chartKV[k]){      //如果hartKV[k]存在，我们就在变量后面push添加我们要的数据
										// console.log(chartKV[k])
										xArr1.push(chartKV[k].fuzailv)
										xArr2.push(chartKV[k].gongshuiwendu)
										xArr3.push(chartKV[k].huishuiwendu)
									}else{   //否者就是0
										xArr1.push("")
										xArr2.push("")
										xArr3.push("")
									}
								}
								
								// for (let item of res.data.chart_data) {
								// 	xArr1.push(item.fuzailv)
								// 	xArr2.push(item.gongshuiwendu)
								// 	xArr3.push(item.huishuiwendu)
								// }
					let myChart = this.$echarts.init(document.getElementById("source"))
			var options = {
				title: [{
					  top:'10',
				       left: 'center',
				       text: '系统运行负载率',
					   textStyle: {
						   color: "rgba(245, 242, 242, 1)",
							fontWeight: "500"
						  },
											  
				   }],
				   legend: {
				       data: ['负载率', '供水温度', '回水温度'],
				   	x:'center',
				   	y:'bottom',
				   	 p:[150,0,0,0],
				   	textStyle: {
				   				color: '#ffffff',
				   				fontSize: '16',
				   			}
				   },
				 tooltip: {
				        trigger: 'axis'
				    },
				 
				  
				    xAxis: {
				        type: 'category',
				        boundaryGap: false,
				        data: yArr,
						axisLabel: {
							textStyle: {
								color: '#c8c8c8',
								fontSize: '12' //字体大小设置
							}
						},
				    },
				   yAxis: [
				       {
				           type: 'value',
				          axisLabel: {
				          	textStyle: {
				          		color: '#c8c8c8',
				          		fontSize: '12' //字体大小设置
				          	}
				          },
						   splitLine: { //网络格的显示，和颜色设置
						   			show: true,
						   			lineStyle: {color: ['#0b4aaf'],}
						   		}
				       },
				       {
				           type: 'value',
				   		splitLine:false,  //横线消失
				           // name: '温度',
				       //     axisLabel: {
				   			 // show: true,
				       //         formatter: '{value} °C'
				       //     },
						   axisLabel: {
							    formatter: '{value} °C',
						   	textStyle: {
						   		color: '#c8c8c8',
						   		fontSize: '12' //字体大小设置
						   	}
						   },
				       }
				   ],
				    series: [
				             {
				                 name: '负载率',
				                 type: 'line',
								   // 仅仅修改标注点颜色
								 itemStyle: {
									   color: '#ffff00'
									 },
								
				                 // stack: '总量',
				                 data:xArr1,
								 symbol: "none" //去圆点
				             },
				             {
				                 name: '供水温度',
				                 type: 'line',
								  // symbol: 'circle',     //设定为实心点
								  symbolSize: 10,   //设定实心点的大小
								  itemStyle: {
										   color: '#c44f72'
										 },
				                 // stack: '总量',
				     			yAxisIndex: 1,
				                 data: xArr2
				             },
				             {
				                 name: '回水温度',
								 symbolSize: 10,   //设定实心点的大小
								// symbol: "none", //去圆点
								itemStyle: {
										   color: '#0ec4a6'
										 },
				                 type: 'line',
				     			yAxisIndex: 1,
				                 // stack: '总量',
				                 data: xArr3
				             }
				      
				    ]
				
			};
			myChart.setOption(options)
			}
			})
				},
		}
	}
</script>

<style>
	*{
		padding: 0;
		margin: 0;
	}
	.module_4{
		margin: 0 auto;
		width: 100%;
		height: 53.75rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mbbg.png) no-repeat;
		background-size: 100% 100%;
		color: #FFFFFF;
	}
	
	.top4{
		display: flex;
		margin: 30px auto;
	}
	
	.title4{
		position: relative;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
		background-size: 100% 100%;
		margin: 10px auto;
		width: 28rem;
		height: 4rem;
		line-height: 5rem;
		text-align: center;
		font-size:1.2rem ;
		
	}
	.title-img4{
		position: absolute;
		width: 3rem;
		height: 3rem;
		line-height: 3.5rem ;
		border-radius: 50%;
		border:1px #fff solid;
		top: -1.5625rem;
		 left: 12.5rem;
		 background: #09337B;
		
	}
	.title-img4 img{
		width: 1.8rem;
		height: 1.5125rem;
	}
	.item4{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.item-box4{
		position: relative;
		text-align: center;
		   width: 26.5rem;
		    height: 8.0625rem;
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg.png)no-repeat;
		background-size: 100% 100%;
		padding-top: 1.5rem;
	}
	.item-image4{
		position: absolute;
		   /* width: 2.25rem;
		    height: 2.25rem; */
		    line-height: 2.525rem;
		    border-radius: 50%;
		    left: 9.3rem;
		    top: 4.2rem;
	}
	.item-image4 img{
		width: 1.5rem;
		height:1.4rem;
	}
	.bottom-line4{
		margin: .7rem auto;
		width: 55%;
		border-bottom: 1px #fff solid;
	
	}

	.echarts-source{
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/mhbg1.png)no-repeat;
		background-size: 100% 100%;
		margin: 1.625rem auto;
		width: 100%;
		height: 28rem;
	}
</style>
