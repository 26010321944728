<template>
	<el-row>
		<div class="backg" id="backg"> </div>
		<div class="grid-content bg-purple-dark">
			<h1>长三角一体化绿色运营管理平台</h1>
		</div>
		<!-- 左边布局 -->
		<el-col :span="7" style="height: 90%;">
			<div class="grid-content bg-purple" style="height: 100%;">
				<div class="left-circle">
					<nav>
						<div class="nav-item nav-item-1" :class="toggleIndex==0? 'current':''" @click="toggleIndex=0">
							<span>&nbsp;建筑面积</span></div>
						<div class="nav-item nav-item-2" :class="toggleIndex==1? 'current-2':''" @click="toggleIndex=1">
							<span>&nbsp;业主数量</span></div>
						<div class="nav-item nav-item-3" :class="toggleIndex==2? 'current-3':''" @click="toggleIndex=2">
							<span>&nbsp;服务年限</span></div>
					</nav>
					<div>
						<div id="circle" style="height: 18.75rem; width: 30.625rem;margin-top: 1.25rem;" v-show="toggleIndex==0"></div>
					</div>
					<div>
						<div id="bar" style="height: 18.75rem; width: 30.625rem;margin-top: 1.25rem;" v-show="toggleIndex==1"></div>
					</div>
					<div>
						<div id="pie" style="height: 18.75rem; width: 30.625rem;margin-top: 2.25rem;" v-show="toggleIndex==2"></div>
					</div>
				</div>
				<!-- echats -->
				<div id="left-pillar">
					<h1 style="width: 12.25rem;margin: 1.25rem 0 0 0;"><span >碳排行榜</span></h1>
					<div id="pillar" style="height: 18.75rem; width: 28.5rem; top: -1rem;"></div>
				</div>
			</div>
		</el-col>
		<!-- 中间布局 -->
		<el-col :span="10" style="height:90%; ">
			<div class="grid-content bg-purple-light" style="height:90%;">
				<div class="main-top">
					<div class="main-text">
						<span class="span-1">区域: &nbsp;</span>
						<span class="span-2"
							@click="renderNext(selectedList[0].id,selectedList[0].title,selectedList[0].map_image,0)">{{selectedList[0].title?selectedList[0].title:selectedList[0].title}}
							></span>
						<span class="span-2"
							@click="renderNext(selectedList[1].id,selectedList[1].title,selectedList[1].map_image,1)"
							v-if="selectedList[1].title">{{selectedList[1].title?""+selectedList[1].title:selectedList[1].title}}></span>
						<span class="span-2" v-else>省 ></span>
						<span class="span-2"
							@click="renderNext(selectedList[2].id,selectedList[2].title,selectedList[2].map_image,2)"
							v-if="selectedList[2].title">{{selectedList[2].title?""+selectedList[2].title:selectedList[2].title}}></span><span
							class="span-2" v-else>市 ></span>
						<span class="span-2"
							@click="renderNext(selectedList[3].id,selectedList[3].title,selectedList[3].map_image,3)"
							v-if="selectedList[3].title">{{selectedList[3].title?""+selectedList[3].title:selectedList[3].title}}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</span>
						<span class="span-2" v-else>区 |&nbsp;&nbsp;&nbsp;&nbsp;</span>

						<span class="span-2">{{title||"全部类别"}} |&nbsp;&nbsp;&nbsp;&nbsp;</span>
						<span class="span-2">{{counts}}栋</span>
						<img @click="Show()"
							src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/fangdajing%E5%A4%87%E4%BB%BD.png">
						<template-architect :selectedList="selectedList" :areaList="areaList"
							@selectedListChange="selectedListChange" @areaListChange="areaListChange" ref="selector">
						</template-architect>
					</div>
				</div>
				<el-dialog :visible.sync="carouselVisible" :destroy-on-close="carouseOnClose">
					<template-carousel v-if="carousel"  :buildingId="clickBuildingId" 
					style="margin: -6.25rem 0 0 -30.25rem;"></template-carousel>
				</el-dialog>
				<div class="main-item">
					<div class="item-center">
						<img :src="bgImg">
						<div class="item-img">
							<span v-for="item in currentAreaList"
								:style="'margin-left:'+item.margin_left*100+'%;margin-top:'+item.margin_top*100+'%'"
								@click="renderNext(item.id,item.title,item.map_image,currentLevel)"><i>{{item.title}}{{item.short_name}}</i><br>
								<div>
									<img class="svga_img"
										src="https://ibest.oss-cn-beijing.aliyuncs.com/web3x/%E9%8D%A7%E6%84%AD%E7%88%A31%403x.png"
										style="width:2.5rem;">
									<div class="json_svga" :id="'pointer'+item.id"></div>
								</div>
							</span>
						</div>

					</div>
				</div>
				<!-- 中间下面 -->
				<div class="mian-icon">
					<div class="icon">
						<div class="icon-bg">
							<img
								src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/003%E5%A4%87%E4%BB%BD%204.png"><br>
							<span>建筑能耗监测<strong id="building"> {{statistics.electric||0}} </strong>栋</span><br>
							<span>碳减排<strong id="corban-1"> {{statistics.electric_CXS||0}} </strong>吨</span>
						</div>
					</div>
					<div class="icon">
						<div class="icon-bg">
							<img
								src="../../assets/airkong.png"><br>
							<span>中央空调系统<strong id="air"> {{statistics.air||0}} </strong>栋</span><br>
							<span>碳减排<strong id="corban-2"> {{statistics.air_CXS||0}} </strong>吨</span>
						</div>
					</div>
					<div class="icon">
						<div class="icon-bg">
							<img
								src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/icon/003%E5%A4%87%E4%BB%BD%205.png"><br>
							<span>多联机空调<strong id="onlineAir"> {{statistics.vrv||0}} </strong>栋</span><br>
							<span>碳减排<strong id="corban-3"> {{statistics.vrv_CXS||0}} </strong>吨</span>
						</div>
					</div>
				</div>
			</div>
		</el-col>
		<!-- 右边布局 -->
		<el-col :span="7" style="height: 90%;">
			<div class="grid-content bg-purple" style="height: 100%; margin-right: 1.875rem;">
				<div class="right-bg" style="">
					<h1 v-if="names" style="width: 13.25rem;margin-top: .6rem;"><span>{{names}}</span></h1>
					<!-- <h1 else style="width: 13.25rem;"><span>请选择</span></h1> -->
					<div class="aside-column" style="padding-left: 3.75rem;">
						<div class="column" >
							<small><big id="amount">{{areaStatistics.building_count||0}}</big>栋</small><br>
							<div class="json " id="orange"></div>
							<span>建筑总数</span>
						</div>
						<div class="column">
							<small><big id="area">{{areaStatistics.total_area_value}}</big>万㎡</small><br>
							<div class="json" id="blue"></div>
							<span>监测面积</span>
						</div>
						<div class="column">
							<small><big id="tce">{{areaStatistics.PAE}}</big>kWh</small><br>
							<div class="json green" id="green"></div>
							<span>建筑能耗</span>
						</div>
						<div class="column"  style="margin-top: 1rem;">
							<small><big id="indicator">{{areaStatistics.NHZB}}</big>kWh/㎡</small><br>
							<div class="json purple" id="purple"></div>
							<span>能耗指标</span>
						</div>
						<div class="column"  style="margin-top: 1rem;">
							<small><big id="emission">{{areaStatistics.CPF}}</big>吨</small><br>
							<div class="json red" id="red">
							</div>
							<span>年碳排放</span>
						</div>
						<div class="column"  style="margin-top: 1rem;">
							<small><big id="reduction">{{areaStatistics.CXS}}</big>吨</small><br>
							<div class="json yellow" id="yellow"></div>
							<span>年碳减排</span>
						</div>
					</div>
				</div>
				<!-- 轮播 -->
				<div class="carousel" style="height: 62%; ">
					<el-carousel style="height:100%;" indicator-position="none">
						<el-carousel-item v-for="(item,index) in banner">
							<h1 style="font-size: 1rem;width: 13.25rem;margin-top: 1.5rem;"><span >{{item.name}}</span></h1>
							<div class="banner-building" style="margin-top: 6%; height: 33%;">
								<img class="el-img" :src="item.buildingTypeImage">
								<div class="txt" style="margin-left:4.3rem;margin-top: -6.25rem;">
									<small style="margin-left: -3rem;"><big
											id="unit_area">{{item.EM1}}</big>kgce/㎡/y</small><br>
									<img style="width: 100%;"
										src="https://ibest.oss-cn-beijing.aliyuncs.com/web3x/Shape%2027%402x1.png"><br>
									<span>单位面积能耗</span>
								</div>
								<div class="txt txt2">
									<small style="margin-left:3.9rem;"><big
											id="architect_area">{{item.area_value}}</big>平方米</small><br>
									<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web3x/Shape%2027%402x.png"><br>
									<span>建筑面积</span>
								</div>
								<div class="txt" style="margin-left:18.25rem;">
									<small style="margin-left:3.2rem;"><big id="person_area">{{item.people_EM1}}</big>
										kgce/r/y</small><br>
									<img src="https://ibest.oss-cn-beijing.aliyuncs.com/web3x/Shape%2027%402x.png"><br>
									<span>人均消耗</span>
								</div>
							</div>
							<div style="display: flex;">
								<h1 style="font-size: 1rem;width: 16rem;"><span>{{item.charts_name}}</span></h1>
								<img style="width:10.375rem;height: 1.8125rem;"
									src="https://ibest.oss-cn-beijing.aliyuncs.com/web2.0/ggg.png" alt="">
							</div>
							<div style="height: 43%; ">
								<div :id="'echarts-right-'+index"
									style="height:15.4rem;width:100% ; position: absolute; bottom: -1.5rem;left: 1rem;">
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>

			</div>
		</el-col>
	</el-row>
</template>
<script>
	import echarts from 'echarts'
	import SVGA from "svgaplayerweb"
	// 弹窗
	import architect from "/src/components/architect.vue"
	import carousel from "/src/components/carousel.vue"
	// vue全局总领事件
	import {
		EventBus
	} from "/src/util/event-bus.js"
	export default {
		components: {
			'template-architect': architect,
			'template-carousel': carousel,
		},
		data() {
			return {
				// 父传子
				// areaId:10,
				clickBuildingId:null,
				isShow: false,
				// selectedList:当前已选择的区域数据【大区，省，市，区】
				selectedList: [{
					id: 0,
					title: "大区名称",
					map_image: ""
				}, {
					id: 0,
					title: "省",
					map_image: ""
				}, {
					id: 0,
					title: "市",
					map_image: ""
				}, {
					id: 0,
					title: "区",
					map_image: ""
				}],
				//areaList:对应的省市区的下拉列表数据【大区，省，市，区】
				areaList: [
					[],
					[],
					[],
					[]
				],
				title: '建筑类别',
				counts: '',
				activeImg: 0,
				// 用水用电
				currentAreaList: [],
				areaArr: [[],[],[],[]],
				//弹窗
				destroyOnClose: true,
				dialogVisible: true,
				architect: false,
				// 弹窗二
				carouseOnClose: true,
				carouselVisible: false,
				carousel: false,

				buildingId: 1,
				selectedArr: [],
				toggleIndex: 0,
				levelArr: [true, false, false, false],
				currentLevel: 0,
				statistics: {},
				areaStatistics: {},
				category: {},
				areaButton: [],
				city: [],
				locality: [],
				system: {},
				list: [],//建筑启用的模板列表
				popup: {},
				bgImg: "",
				image: "",
				names: "",
				name: {},
				// 地图
				map: {},
				// 轮播
				banner: {},
				titles: '',
				selected_category_id:''
				
				// windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
				// windowHeight: document.documentElement.clientHeight, //实时屏幕高度
			};
		},


		//调用
		mounted() {
			// const that=this
			// window.onresize = () => {
			// 	return (() => {
			// 		window.fullHeight = document.documentElement.clientHeight;
			// 		window.fullWidth = document.documentElement.clientWidth;
			// 		that.windowHeight = window.fullHeight; // 高
			// 		that.windowWidth = window.fullWidth; // 宽console.log("实时屏幕高度：", that.windowHeight);
			// 		console.log("实时屏幕宽高：", that.windowWidth,that.windowHeight);
			// 		var $_canvas = document.getElementById("app");
			// 		   $_canvas.style.width = that.windowWidth + "px";
			// 		   $_canvas.style.height = that.windowHeight + "px";
			// 	})()
			// };
			this.getFirstAreaList()
			// svga
			this.orange();
			this.blue();
			this.purple();
			this.red();
			this.yellow();
			this.green();
			this.get_pointer();
			this.earth();
			// 
			this.backg();
			this.autoFit();
			this.setFill("backg")
			// 调用接口
			this.get_statistics() //总系统
			// this.get_areaStatistics() //区域系统
			// this.renderNext(35,"大区名称","",0,"","") //第一层按钮
			this.get_banner()
			// echarts
			this.echartsPillar()
			this.echartsCircle()
			this.echartsPie()
			this.echartsBar()
		},

		methods: {
			Show() {this.$refs.selector.show()},
			areaListChange(areaList, title) {
				this.areaList = areaList
				this.title = title
			},
			selectedListChange(selectedList,selected_category_id) {
				let selectedObj = {}
				let i = 0
				let level = 0
				this.selectedList = selectedList
				this.selected_category_id=selected_category_id
				// console.log(this.selected_category_id,this.selectedList,'建筑类别的id')
				for (let item of this.selectedList) {
					if (item.id && item.id != 0) {
						selectedObj = item
						level = i
					}
					i++
				}
				console.log('选中的最后一个是', selectedObj, level,selected_category_id)
				// debugger
				this.renderNext(selectedObj.id, selectedObj.title, selectedObj.map_image, level,selected_category_id)
			
			},
			// 子传父
			// getData(getSelected, getTitle, lastId, lastImg,lastSelected_category_id) {
			// 	let lastName = ""
			// 	let level = 0
			// 	let lastLevel = 0
			// 	this.selectedList = getSelected
			// 	this.title = getTitle //建筑类别
			// 	this.selected_category_id=lastSelected_category_id
			// 	for (let item of this.selectedList) {
			// 		console.log(item, "vvvvvvvvvvvvv")
			// 		if (item.id != 0) {
			// 			lastId = item.id
			// 			lastImg = item.map_image
			// 			lastName = item.title
			// 			lastLevel = level
			// 		}
			// 		level++
			// 	}
			// 	// console.log(lastSelected_category_id,'ddddddddddddddddid')
			// 	console.log('选中的最后一个是：' + lastId, lastName, lastLevel,lastSelected_category_id,"ppppppppppppppppppppppppppp")
			// 	this.renderNext(lastId, lastName, lastImg, lastLevel,lastSelected_category_id)
			// },
			
			// 返回
			// goBack() {
			// 	this.currentLevel--
			// 	// this.currentLevel=this.currentLevel-1
			// 	console.log(this.currentLevel, "this.currentLevel")
			// 	let level = this.currentLevel //我在currentLevel后面添加了-1，在最后一级按钮点击时就不用点击返回两次了,但是又会出现另一个bug，点击第二级返回不了
			// 	console.log(level, "level")
			// 	if (level > 0 || level == 0) { //||level==0这样写就可以返回第一层按钮，而不会返回触发不了事件，或者返回-1-2-3这种
			// 		this.levelArr[level] = true
			// 		this.levelArr[level + 1] = false
			// 		this.$forceUpdate()
			// 		this.list = []
			// 		this.bgImg = areaId
			// 	}
			// },
			changeShow(level) {
				if (level < 3) {
					this.levelArr[level] = false
					this.levelArr[level + 1] = true
					this.currentLevel = level + 1
				}
			},
			// 点击高亮
			selectMenu(index, areaId) {
				this.changeShow(index)
				this.selectedArr[index] = areaId
			},
			// list
			openDialog() {
				// debugger
				this.dialogVisible = true
				this.listActive = index
			},
			open(key, index,buildingId,list) {
				this[key] = true
				this.carouselVisible = true
				this.carouseActive = index
				console.log(list,"77777777777")
				
				this.$nextTick(function() { //等待dom渲染完成
					EventBus.$emit("aMsg",buildingId,list);
				})
				// this.$refs.alertModal.changePeriod(0,this.clickBuildingId)
				
				
			},
			get_statistics() {
				this.$axios.get('/api/web/statistics').then(res => {
					if (res.errcode == 0) {
						this.statistics = res.data;
						console.log(this.statistics, '123')
					}
				})
			},
			get_areaStatistics(id) {
				this.$axios.get('/api/web/area/statistics', {
						params: {
							area_id: id
						}
					})
					.then(res => {
						if (res.errcode == 0) {
							this.areaStatistics = res.data;
						}
					}).catch(res => {
						this.areaStatistics = []
						console.log(res, "ressssssssssssss")
						// 这里的res就是我们axios响应错误errorpromise的值
					})
			},

			// 建筑类别
			construction_type() {
				console.log("qqqqqqqqqqqqqqqqqqqq")
				this.$axios.get('/api/web/building_category/list?a=2')
					.then(res => {
						if (res.errcode == 0) {
							this.category = res.data;
							console.log(this.category, "this.cvvvvvvvvvvvvvvvv")
						}
					})
			},

			getFirstAreaList() {
				this.$axios.get('/api/web/area/get_list_by_id?area_id=0').then(res => {
					if (res.errcode == 0 && res.data.length > 0) {
						// this.bgImg=res.data[0].map_image
						this.areaList[0] = res.data
						let firstArea = res.data[0]
						this.renderNext(firstArea.id, firstArea.title, firstArea.map_image, 0)
					}
				})
			},
			renderNext(areaId, title, mapImage, level) {
				console.log("当前点击de是" + level + "级:" + areaId, title, this.currentLevel,this.selected_category_id)
				// console.log(areaId,lastSelected_category_id,"lastIdlastIdlastIdlastId")
				if (mapImage) {
					this.bgImg = mapImage
				}
				if (title) {
					this.names = title
				}
				let that = this
				if (level < 3) {
					that.$axios.get('/api/web/area/get_list_by_id?area_id=' + areaId).then(res => {
						this.currentAreaList = []
						if (res.errcode == 0 && res.data.length > 0) {
							that.areaList[level + 1] = res.data
							// this.$refs.robin.changeAreaList(that.areaList)
							// that.$set(that.areaList,level+1,res.data)
							that.get_areaStatistics(areaId);
							that.selectMenu(level, areaId)
							that.areaArr[level + 1] = res.data
							that.currentAreaList = res.data
							that.currentLevel = level
							that.$nextTick(function() { //等待dom渲染完成
								that.currentLevel++
								res.data.forEach((v, k) => { //基于已加载好的div 。进行循环svga动画的渲染
									that.renderSvga(v.id)
								})
							})
						}
					})
				} else if (level == 3) {
					that.currentAreaList=[]  //把第三级置空，然后再调用接口，这样就不会出现，如果第四级只有图片，没有建筑名字，第三级的建筑名称出现到第四级问题
					
					let paramStr='area_id=' + areaId
					if(this.selected_category_id){
						paramStr=paramStr+'&type_id='+this.selected_category_id
					}
					this.$axios.get('/api/web/building/arr?'+paramStr).then(res => {
						// 以下是其他方法
						// let param={params:{area_id:areaId}}
						// if(this.selected_category_id){
						// 	param.params.type_id=this.selected_category_id
						// }
						// console.log(param,'param1')
						// this.$set(param.params,'test',123)
						// console.log(param,'param2')
						// this.$axios.get('/api/web/building/arr',param).then(res => {
							
						if (res.errcode == 0 && res.data.length > 0) {
							that.get_areaStatistics(areaId);
							that.selectMenu(level, areaId)
							that.areaArr[level + 1] = res.data
							that.currentAreaList = res.data
							that.currentLevel = level
							that.$nextTick(function() { //等待dom渲染完成
								that.currentLevel++
								res.data.forEach((v, k) => { //基于已加载好的div 。进行循环svga动画的渲染
									that.renderSvga(v.id)
								})
							})
						}
					})
				} else if (level == 4){
					this.clickBuildingId=areaId
					// debugger
					this.$axios.get('/api/web/template/arr?building_id=' + areaId)
					.then(res => {
						if (res.errcode == 0 && res.data.length > 0) {
							// this.areaStatistics = {
							// 	"CO2XH": "0.0",
							// 	"CO2PF": "0.0",
							// 	"EM1": 0.0,
							// 	"total_area_value": 0.0,
							// 	"building_count": 0,
							// 	"NHZB": "0.0"
							// };
							this.list = res.data;
							this.selectMenu(level, areaId)
							this.open('carousel',null,areaId,this.list)
						}
					})
				}
				// 控制
				if (level <= 3) {
					
					let countStr='area_id=' + areaId
					if(this.selected_category_id){
						countStr=countStr+'&type_id='+this.selected_category_id
					}
					this.$axios.get('/api/web/area/building/count?'+countStr)
					// this.$axios.get('/api/web/area/building/count?area_id='+areaId)
					// {
					// 	params:{
					// 		area_id:areaId,
					// 	}
					// })
						.then(res => { //获取建筑栋数
							if (res.errcode == 0) {
								this.counts = res.data;
								// console.log(this.counts, "wowowowooooooooooooooooooooooooooooo")
							}
						})
					//this.$set数组 才能触发dom 重新渲染数组
					this.$set(this.selectedList, level, {
						id: areaId,
						title: title,
						map_image: mapImage
					})
					// 通过控制selectedList的层级来改变id，title，mapimage，实现地图和区域联动
					if (level < 3) {
						for (let i = (level + 1); i <= 3; i++) {
							this.$set(this.selectedList, i, {})
						}
					}
					// this.selectedList[level]={id:areaId,title:title,map_image:mapImage}
					// 通过控制selectedList的层级来改变id，title，mapimage，实现地图和区域联动
					// if(level<3){
					//     for(let i=(level+1);i<=3;i++){
					//         this.selectedList[level+1]={}
					//     }
					// }
					// this.$set(this.selectedList,this.selectedList)
					this.$refs.selector.changeArea({
						id: areaId,
						title: title,
						map_image: mapImage
					}, level)
					// console.log(this.areaList, '区域下拉列表')
					// console.log(this.selectedList, '选中区域列表')
				}
			},

			// 右下角轮播渲染
			get_banner() {
				this.$axios.get('/api/web/star_building/list')
					.then(res => {
						if (res.errcode == 0) {
							this.banner = res.data
							this.$nextTick(() => {
								res.data.forEach((v, k) => { //charts
									this.createChart(k, v.charts)
									// console.log(v.charts, "v.charts")
								})
							})
						}
					})
			},
				createChart(index, chartsData) {
					let myChart = this.$echarts.init(document.getElementById("echarts-right-" + index))
					// debugger
					let xArr = [],yArr = [],lineArr = [],xLineArr = []
	                const dateArr=['01', '02','03', '04', '05' , '06', '07','08','09','10', '11', '12'];
					chartsData.forEach(function(v, k) {
						lineArr.push(v.year)
	                    yArr[k]=[]
	                    for(let month of dateArr){
						yArr[k].push(v.data[month]?v.data[month]:0)
	                    }
						// xArr.push(Object.keys(v.data))
						// yArr.push(Object.values(v.data))
					})
					var options = {
						title:{
						 subtext: "MWh",
						  subtextStyle: {
						       color: "#709FD9"
						     },
							 left:'10',
							 top:'15'
						},
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'cross',
								crossStyle: {
									color: '#709FD9'
								}
							}
						},
	
						legend: {
							data: lineArr,
							itemWidth: 30, //图例的宽度
							itemHeight: 15, //图例的高度
							textStyle: {
								color: '#ffffff',
								fontSize: '16',
							}
						},
						xAxis: [{
							type: 'category',
							data:dateArr,
							axisPointer: {
								type: 'shadow'
							},
							axisLabel: {
								interval: 0, // 月份全部显示
								textStyle: {
									color: '#709FD9',
									// color: '#c8c8c8',
									fontSize: '12' //字体大小设置
								}
							},
						}],
						yAxis: [{
								type: 'value',
								axisLabel: {
									formatter: '{value}' },
								
								// 去网格线的
								splitLine: {
									show: false
								},
								axisLabel: {
									textStyle: {
										color: '#709FD9',
										fontSize: '12' //字体大小设置
									}
								},
							},
						],
						series: [{
							barWidth: '60%',   //柱子宽度
								name: lineArr[0],
								type: 'bar',
								stack: 'overlap', //堆叠效果，字符需要统一
								data: yArr[0],
								itemStyle: {
									normal: {
										color: '#3BADEE'
									}
								}
							},
							{
								name: lineArr[1],
								type: 'bar',
								stack: 'overlap', //堆叠效果，字符需要统一
								data: yArr[1],
								// data: yArr,
								itemStyle: {
									normal: {
										color: '#19F1EC'
									}
								}
							},
						]
					}
					myChart.setOption(options)
				},
			// 建筑面积-饼图
			echartsCircle() {
				this.$axios.get('/api/web/area/rank').then(res => {
					let data = []
					let tempObj = {}
					let colorStartArr = ['#900D55', '#D25310', '#488911', '#1575C1', '#4D14AB', "#7079EE"]
					let colorEndArr = ['#E064A3', '#FBB226', '#7FD36C', '#1575C2', '#7F79EE', "#7A79EE"]
					if (res.errcode == 0) {
						for (let i in res.data.chartData) {
							// console.log(res.data.chartData,"lalalalalaallaallaa")
							let item = res.data.chartData[i]
							// debugger
							tempObj = {
								value: item.area,
								name: item.type_name,
								itemStyle: {
									color: {
										type: 'linear',
										x: 0,y: 0,x2: 0,y2: 1,
										colorStops: [{
											offset: 0,
											color: colorStartArr[i]
										}, {
											offset: 1,
											color: colorEndArr[i]
										}],
										global: false
									}
								}
							}
							data.push(tempObj)
						}
						let myChart = this.$echarts.init(document.getElementById("circle"))
						let options = {
							title: {
							            zlevel: 0,
							            text: "总面积m³",
										 subtext: [res.data.allSum],
										 textStyle: {color: "#fff",
										 fontSize:"12",},
										 subtextStyle:{color: "#ccc"},
										 x: 'center',
										 y: 'center',
							        },

							tooltip: {
								trigger: 'item',
								 formatter: '{b} <br/>{c} ({d}%)'
							},
							series: [{
								radius: [60, 90], //设置扇形大小
								name: ['访问来源'],
								type: 'pie',
								label: {color: '#ccc'},
								data: data,
							}]
						}
						myChart.setOption(options)
					}
				})
			},

			// 业主数量-柱形图
			echartsBar() {
				this.$axios.get('/api/web/charts/owner').then(res => {
					if (res.errcode == 0) {
						let xArr = Object.keys(res.data)
						let yArr = Object.values(res.data)
						let myChart = this.$echarts.init(document.getElementById("bar"))
						var options = {
							tooltip: {
								trigger: 'axis',
								axisPointer: { // 坐标轴指示器，坐标轴触发有效
									type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
								},
								formatter: "{b}<br/>总量:{c}"  //,{a0}后面添加单位
							},
							grid: {
								left: '3%',
								right: '4%',
								bottom: '3%',
								containLabel: true
							},
							xAxis: [{
								type: 'category',
								data: xArr,
								axisTick: {
									alignWithLabel: true
								}
							}],
							yAxis: [{
								type: 'value',
								splitLine: {
									show: false
								},
							}],
							series: [{
									name: '直接访问',
									type: 'bar',
									barWidth: '20%', //柱子宽度
									data: yArr,
									showBackground: true,
									backgroundStyle: {
										color: 'rgba(180, 180, 180, 0.2)'
									}
								},

							]
						};
						myChart.setOption(options)
					}
				})
			},

			// 服务年限-饼图
			echartsPie() {
				this.$axios.get('/api/web/charts/server_year').then(res => {
					if (res.errcode == 0) {
						let data = []
						for (let i in res.data) {
							let item = res.data[i]
							let tempObj = {
								value: item.num,
								name: item.title,
							}
							data.push(tempObj)
						}
						let myChart = this.$echarts.init(document.getElementById("pie"))
						var options = {
							   tooltip: {trigger: 'item'},
							    series: [
							        {
							            // name: '访问来源',
							            type: 'pie',
							            radius: '65%',
							            data: data,
										label: {color: '#ccc'},
							            emphasis: {
							                itemStyle: {
							                    shadowBlur: 10,
							                    shadowOffsetX: 0,
							                    shadowColor: 'rgba(0, 0, 0, 0.5)'
							                }
							            }
							        }
							    ]
						}
						myChart.setOption(options)
					}
				})
			},

			// 区域年碳排放（前10）-纵向柱状图

			// 排行榜
			echartsPillar() {
				this.$axios.get('/api/web/area/cpf_rank').then(res => {
					if (res.errcode == 0) {
						let xArr = []
						let yArr = []
						// debugger
						for (let item of res.data) {
							xArr.push(item.CPF)
							yArr.push(item.title)
						}
						let myChart = this.$echarts.init(document.getElementById("pillar"))
						var options = {
							tooltip: {
								trigger: 'axis',
								axisPointer: {
									type: 'shadow'
								},
								formatter: '{b0}:{c0} 吨'  //,后面添加单位
							},
							grid: {
								left: '3%',
								right: '4%',
								bottom: '3%',
								containLabel: true,
							},
							xAxis: {
								type: 'value',
								min:0,
								max: 40000,
								interval: 500,
								name: "吨",
								boundaryGap: [0, 0.01],
								label: {
									show: true
								},
								splitLine: {
									show: false
								},
								axisLabel: {
									textStyle: {
										color: 'none', //字体不显示
									}
								},
							},
							yAxis: {
								type: 'category',
								data: yArr,
								inverse: true, //从高到底排序，false从低到高排序
								axisLabel: {
									textStyle: {
										color: '#ffffff',
										fontSize: '12' //字体大小设置
									}
								},
							},
							series: [{
								barWidth: '50%', //柱子宽度
								showBackground: true, //更改背景柱子颜色
								backgroundStyle: {
									color: '#0B1839' //更改背景柱子颜色

								},
								label: {
								
								normal:{
									show: true,
									color:"#fff",
									fontSize:'13',
									position:'right'  //insideBottom柱形图内部文字靠右，right外部靠右
								}
								
								},
								type: 'bar',
								data: xArr,
								itemStyle: {
									normal: {
										color: '#04BBCF' //更改柱子颜色
									}
								}

							}]
						}
						myChart.setOption(options)
					}
				})
			},



			//svga
			orange() {
				let player = new SVGA.Player("#orange");
				let parser = new SVGA.Parser("#orange");
				parser.load('https://ibest.oss-cn-beijing.aliyuncs.com/svga/svga/%E6%A9%99%E8%89%B2.svga', function(
					videoItem) { //this.bgImg，图片路径需要线上地址，本地引用会报错
					player.setVideoItem(videoItem);
					player.startAnimation();
				});
			},
			blue() {
				let player = new SVGA.Player("#blue");
				let parser = new SVGA.Parser("#blue");
				parser.load('https://ibest.oss-cn-beijing.aliyuncs.com/svga/svga/%E8%93%9D%E8%89%B2.svga', function(
					videoItem) {
					player.setVideoItem(videoItem);
					player.startAnimation();
				});
			},
			red() {
				let player = new SVGA.Player("#red");
				let parser = new SVGA.Parser("#blue");
				parser.load('https://ibest.oss-cn-beijing.aliyuncs.com/svga/svga/%E7%BA%A2%E8%89%B2.svga', function(
					videoItem) {
					player.setVideoItem(videoItem);
					player.startAnimation();
				});
			},
			yellow() {
				let player = new SVGA.Player("#yellow");
				let parser = new SVGA.Parser("#yellow");
				parser.load('https://ibest.oss-cn-beijing.aliyuncs.com/svga/svga/%E9%BB%84%E8%89%B2.svga', function(
					videoItem) {
					player.setVideoItem(videoItem);
					player.startAnimation();
				});
			},

			green() {
				let player = new SVGA.Player("#green");
				let parser = new SVGA.Parser("#green");
				parser.load('https://ibest.oss-cn-beijing.aliyuncs.com/svga/svga/%E7%BB%BF%E8%89%B2.svga', function(
					videoItem) {
					player.setVideoItem(videoItem);
					player.startAnimation();
				});
			},

			purple() {
				let player = new SVGA.Player("#purple");
				let parser = new SVGA.Parser("#purple");
				parser.load('https://ibest.oss-cn-beijing.aliyuncs.com/svga/svga/%E7%B4%AB%E8%89%B2.svga', function(
					videoItem) {
					player.setVideoItem(videoItem);
					player.startAnimation();
				});
			},
			get_pointer() {
				let player = new SVGA.Player("#pointer");
				let parser = new SVGA.Parser("#pointer");
				parser.load('https://ibest.oss-cn-beijing.aliyuncs.com/svga/svga/%E6%8C%87%E9%92%88.svga', function(
					videoItem) {
					player.setVideoItem(videoItem);
					player.startAnimation();
				});
			},
			renderSvga(id) {
				// console.log(id,"renderSvga.id")
				let player = new SVGA.Player("#pointer" + id);
				// console.log(player, 'player')
				let parser = new SVGA.Parser("#pointer" + id);
				parser.load('https://ibest.oss-cn-beijing.aliyuncs.com/svga/svga/%E6%8C%87%E9%92%88.svga', function(
					videoItem) {
					player.setVideoItem(videoItem);
					player.startAnimation();

				});
			},
			// 地球

			earth() {
				let player = new SVGA.Player("#earth");
				let parser = new SVGA.Parser("#earth");
				parser.load('https://ibest.oss-cn-beijing.aliyuncs.com/svga/svga/%E5%9C%B0%E7%90%83.svga', function(
					videoItem) {
					player.setVideoItem(videoItem);
					player.startAnimation();
				});
			},

			// 背景图片
			backg() {
				let player = new SVGA.Player("#backg");
				let parser = new SVGA.Parser("#backg");
				parser.load('https://ibest.oss-cn-beijing.aliyuncs.com/svga/svga/%E5%9B%BE%E5%B1%82%20179.svga', function(
					videoItem) {
					player.setVideoItem(videoItem);
					player.startAnimation();
				})
			},
			autoFit() {
				var $_canvas = document.getElementById("app");
				$_canvas.style.width = window.innerWidth + "px";
				$_canvas.style.height = window.innerHeight + "px";
			},
			setFill(id) { //适配不同屏
				var $_canvas = document.getElementById(id);
				var w = window.innerWidth;
				var h = window.innerHeight;
				var screen_proportion = h / w;
				var svga_proportion = 9 / 16;
				console.log(w, h, svga_proportion, screen_proportion, 'width&height')
				if (screen_proportion > svga_proportion) {
					//长屏幕
					$_canvas.style.width = h / svga_proportion + "px";
					$_canvas.style.left = (w - h / svga_proportion) / 2 + "px";
				} else {
					$_canvas.style.height = w * svga_proportion + "px";
					$_canvas.style.top = (h - w * svga_proportion) / 2 + "px";
				}
			}


		}


	}
	import("./index.css");
	import("./popup.css");
</script>

<style lang="scss">
</style>
