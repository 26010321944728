import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
	meta:{
		requireAuth:true,  //添加该字段，表示进入这个路由是需要验证的
	}
  },
  
  {
    path: '/login',
    name: '登陆',
    component: () => import( '../views/login/login.vue')
  }
 // {
 //   path: '/home',
 //   name: '登陆',
 //   component: () => import( '../views/index/home.vue')
 // },
 // {
 //   path: '/new',
 //   name: '登陆',
 //   component: () => import( '../views/index/new_file.vue')
 // },
  
]







// router.beforeEach((to,form,next)=>{
	
// 	if(to.meta.requireAuth){
// 		if(store.state.token){
// 			next();
// 		}else{
// 			next({
// 				path:"/login",
// 				query:{redirect:to.fullPath}
// 			})
// 		}
// 	}else{
// 		next();
// 	}
// })



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
