<template>
	<div class="banner">
		<div class="shut" @click="shut()"><span>关闭x</span></div>
		<el-carousel :autoplay="false" arrow="always">
			<el-carousel-item v-if="(list.indexOf('electric')>-1)">
				<my-electric></my-electric>
			</el-carousel-item>

			<el-carousel-item v-if="(list.indexOf('water')>-1)">
				<my-water></my-water>
			</el-carousel-item>

			<el-carousel-item v-if="(list.indexOf('air')>-1)">
				<my-air></my-air>
			</el-carousel-item>

			<el-carousel-item v-if="(list.indexOf('vrv')>-1)">
				<my-vrv></my-vrv>
			</el-carousel-item>

			<el-carousel-item v-if="(list.indexOf('light')>-1)">
				<my-light></my-light>
			</el-carousel-item>

			<el-carousel-item v-if="(list.indexOf('wind')>-1)">
				<my-wind></my-wind>
			</el-carousel-item>

			<el-carousel-item v-if="(list.indexOf('environment')>-1)">
				<my-environment></my-environment>
			</el-carousel-item>

			<el-carousel-item v-if="(list.indexOf('hot')>-1)" >
				<my-source></my-source>
			</el-carousel-item>

			<el-carousel-item v-if="(list.indexOf('atmosphere')>-1)" >
				<my-atmosphere></my-atmosphere>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	import {
		EventBus
	} from "/src/util/event-bus.js";
	export default {
		name: 'template-carousel',

		data() {
			return {
				id: '',
				list: [],
			}
		},
		mounted() {
			EventBus.$on("aMsg", (id, list) => {
				// A发送来的消息
				this.id = id;
				list.forEach((v, K) => {
					// debugger
					this.list.push(v.value)
				})
				this.$nextTick(function() { //等待dom渲染完成,必须要加这一步，不然模板数据不能加载出来
					EventBus.$emit("id",this.id);
				})
				// console.log(this.list, "00000000000000")
				
			});
			
		},
		methods: {
			shut() {
				this.$parent.$emit('update:visible', false)
				
				EventBus.$off('id')   //移除EventBus监听事件，这样就不会一直重复调接口了，要在父页面移除
				// 
				
			}
		}
	}
</script>

<style>
	.banner {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		position: fixed;
		background: rgba(0, 0, 0, .5);
		z-index: 10;
	}

	.el-carousel {
		height: 100%;
		width: 100%;
		z-index: 998;
	}

	.shut {
		text-align: right;
		margin: 1.25rem 1.25rem 0.625rem 0;
		z-index: 999 !important;
		padding-bottom: -50px;
		color: #ccc;

	}

	.shut span {
		background: #004892;
		border-radius: 20px;
		padding: 5px 10px;

	}

	.shut :hover {
		color: #fff;
		background: #004892;
	}
</style>
