import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
// rem
import './util/rem'
Vue.prototype.$echarts = echarts
Vue.use(ElementUI);

// axios
import axios from "./axios.js";
Vue.prototype.$axios=axios;

// vue的全局事件总线
// Vue.prototype.$EventBus = new Vue()


// 全局组件
import electric from "./components/carouselModule/electric.vue";
Vue.component("my-electric",electric)
import air from "./components/carouselModule/air.vue";
Vue.component("my-air",air)
import vrv from "./components/carouselModule/vrv.vue";
Vue.component("my-vrv",vrv)
import light from "./components/carouselModule/light.vue";
Vue.component("my-light",light)
import wind from "./components/carouselModule/wind.vue";
Vue.component("my-wind",wind)
import environment from "./components/carouselModule/environment.vue";
Vue.component("my-environment",environment)
import water from "./components/carouselModule/water.vue";
Vue.component("my-water",water)
import atmosphere from "./components/carouselModule/atmosphere.vue";
Vue.component("my-atmosphere",atmosphere)
import heatSource from "./components/carouselModule/heat_source.vue";
Vue.component("my-source",heatSource)





Vue.config.productionTip = false


// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//       //这里判断用户是否登录，验证本地存储是否有token
//       if (!localStorage.token) { // 判断当前的token是否存在
//           next({
//               path: '/login',
//               query: { redirect: to.fullPath }
//           })
//       } else {
//           next()
//       }
//   } else {
//       next() // 确保一定要调用 next()
//   }
// })



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
